<template lang="pug">
.deposit-terms.card
  .card-header {{ $t('title') }}
    button.btn.btn-link.fa-pull-right.animated.fadeIn.py-0(
      v-if           = '!editing && isEditable'
      :title         = '$t("common:edit")'
      @click.prevent = 'toggleEditing'
    ): i.far.fa-edit.fa-lg
    button.btn.btn-link.fa-pull-right.animated.fadeIn.py-0(
      v-else-if      = 'isActiveRevision'
      key            = 'syncButton'
      :title         = '$t("sync")'
      v-fi-confirm   = '{ ok: runSync, message: $t("syncConfirm") }'
    ): i.fas.fa-sync.fa-lg(:class="{ 'fa-pulse': $vueLoading.isLoading('deposit:product:sync') }")
  .card-body
    form.form.animated.fadeIn(
      v-if            = 'editing'
      @submit.prevent = 'submit'
      novalidate
    )
      fi-form-field(
        v-if      = 'isCompoundInterestPossible'
        :label    = '$t("compoundInterest")'
        :disabled = 'isCompoundInterestDisabled'
      )
        fi-switch(v-model = '$v.termsData.compoundInterest.$model')
      fi-form-field(:label = '$t("autoExtend")')
        fi-switch(v-model = '$v.termsData.autoExtendableEnabled.$model')
      fi-form-field.animated.fadeIn(
        v-if = '$v.termsData.autoExtendableEnabled.$model'
        :label = '$t("additionalInterest")'
        :state      = '!$v.termsData.additionalInterest.$error'
      )
        template(#error)
          .error-message(v-if = '!$v.termsData.additionalInterest.required') {{ $t('common:required') }}
        percent-input.form-control.form-control-sm(
          v-model = '$v.termsData.additionalInterest.$model'
          :class  = '{ "is-invalid": $v.termsData.additionalInterest.$error }'
        )
      fi-form-field(
        :label    = '$t("withholdIncomeTax")'
      )
        .form-row
          .col
            fi-switch(v-model = '$v.termsData.withholdTax.enabled.$model')
          .col.animated.fadeIn(v-if = '$v.termsData.withholdTax.enabled.$model && $v.termsData.withholdTax.residenceCountryId.$model')
            fi-select(
              v-model = '$v.termsData.withholdTax.rate.$model'
              :options       = 'optionsFromClassifierTax($v.termsData.withholdTax.residenceCountryId.$model)'
              :class         = '{ "is-invalid": $v.termsData.withholdTax.rate.$error }'
              required
              sm
            )
      fi-form-field.animated.fadeIn(
        v-if      = '$v.termsData.withholdTax.enabled.$model'
        :label    = '$t("taxResidency")'
      )
        fi-select(
          v-model.number = '$v.termsData.withholdTax.residenceCountryId.$model'
          :options       = 'optionsFromClassifierTaxCountry()'
          :class         = '{ "is-invalid": $v.termsData.withholdTax.residenceCountryId.$error }'
          required
          sm
        )
      fi-form-field(
        :label = '$t("moneyOrigin")'
        :state = '!$v.termsData.originOfMoney.$error'
      )
        template(#error)
          .error-message(v-if = '!$v.termsData.originOfMoney.required') {{ $t('common:required') }}
        fi-multiselect(
          v-model       = '$v.termsData.originOfMoney.$model'
          :options      = 'optionsFromClassifier("incomeSources")'
          :class        = '{ "is-invalid": $v.termsData.originOfMoney.$error }'
          multiple
          hide-selected
        )

      fi-form-field(
        :label = '$t("beneficiary")'
        :state = '!$v.termsData.beneficiary.$error'
      )
        template(#error)
          .error-message(v-if = '!$v.termsData.beneficiary.required') {{ $t('common:required') }}
        input.form-control.form-control-sm(
          v-model.trim = '$v.termsData.beneficiary.$model'
          :class       = '{ "is-invalid": $v.termsData.beneficiary.$error }'
        )
      fi-form-field(
        :label     = '$t("amount")'
        label-cols = 4
      )
        .row
          .col-2.px-1 {{ $t('from') }}
          .col.px-1 {{ amountPeriod.amountRange.from }}
          .col.px-1 {{ $t('to') }}
          .col.px-1 {{ amountPeriod.amountRange.to }}
          .col-5.px-1
      fi-form-field(
        :label     = '$t("interestPeriods")'
        label-cols = 4
      )
        .row(v-for = '(interestPeriod, index) in amountPeriod.periodRangeInterests')
          .col-2.px-1
            template(v-if = 'index === 0') {{ productPeriodUnitType }}
          .col.px-1 {{ $t('from') }}
          .col.px-1 {{ interestPeriod.periodRange.from }}
          .col.px-1 {{ $t('to') }}
          .col.px-1 {{ interestPeriod.periodRange.to }}
          .col.px-1 {{ $t('interest') }}
          .col.px-1
            percent-input.form-control.form-control-sm(v-model = '$v.termsData.interests.$each.$iter[amountPeriodIdx].$model[index]')

      button.btn.btn-primary.mr-sm-2(
        :disabled = 'saving'
        type      = 'submit'
      )
        | {{ $t('common:save') }}
        i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
      button.btn.btn-secondary(
        :disabled      = 'saving'
        @click.prevent = 'toggleEditing'
      ) {{ $t('common:cancel') }}
    template(v-else)
      fi-data-field(
        v-if       = 'isCompoundInterestPossible'
        :label     = '$t("compoundInterest")'
        label-cols = 4
      )
        fi-switch(
          :value = 'deposit.terms.compoundInterest'
          read-only
        )
      fi-data-field(
        :label     = '$t("autoExtend")'
        label-cols = 4
      )
        fi-switch(
          :value = 'deposit.terms.autoExtendable.extendable'
          read-only
        )
      fi-data-field(
        v-if       = 'deposit.terms.autoExtendable.extendable'
        :label     = '$t("additionalInterest")'
        label-cols = 4
      ) {{ deposit.terms.autoExtendable.additionalInterest | percent }}
      fi-data-field(
        :label     = '$t("withholdIncomeTax")'
        label-cols = 4
      )
        .row
          .col
            fi-switch(
              :value = 'deposit.terms.withholdTax.enabled'
              read-only
            )
          .col(v-if = 'deposit.terms.withholdTax.enabled') {{ deposit.terms.withholdTax.rate | percent}}
      fi-data-field(
        v-if       = 'deposit.terms.withholdTax.enabled'
        :label     = '$t("taxResidency")'
        label-cols = 4
      ) {{ classifierById('countries', deposit.terms.withholdTax.residenceCountryId).human }}
      fi-data-field(
        :label     = '$t("moneyOrigin")'
        label-cols = 4
      ) {{ deposit.terms.originOfMoney }}
      fi-data-field(
        :label     = '$t("beneficiary")'
        label-cols = 4
      ) {{ deposit.terms.beneficiary }}
      fi-data-field(
        :label     = '$t("amount")'
        label-cols = 3
      )
        .row
          .col-2 {{ $t('from') }}
          .col {{ amountPeriod.amountRange.from }}
          .col {{ $t('to') }}
          .col-2 {{ amountPeriod.amountRange.to }}
          .col-5
      fi-data-field(
        :label     = '$t("interestPeriods")'
        label-cols = 3
      )
        .row(v-for = '(interestPeriod, index) in amountPeriod.periodRangeInterests')
          .col-2
            template(v-if = 'index === 0') {{ productPeriodUnitType }}
          .col {{ $t('from') }}
          .col {{ interestPeriod.periodRange.from }}
          .col {{ $t('to') }}
          .col {{ interestPeriod.periodRange.to }}
          .col {{ $t('interest') }}
          .col-2 {{ interestPeriod.interest | percent }}

</template>


<script>
import FiDataField from '@/components/FiDataField'
import FiFormField from '@/components/FiFormField'
import FiSelect from '@/components/FiSelect'
import FiMultiselect from '@/components/FiMultiselect'
import FiSwitch from '@/components/FiSwitch'
import PercentInput from '@/components/PercentInput'
import { mapActions, mapGetters, mapState } from 'vuex'
import { ContractDeposit } from '@/models'
import cloneDeep from 'lodash/cloneDeep'
import pick from 'lodash/pick'
import orderBy from 'lodash/orderBy'
import { validation } from '@/validation'
import { loanStatuses } from '@/const'

export default {
  name: 'deposit-terms',

  components: { FiSelect, FiMultiselect, PercentInput, FiFormField, FiDataField, FiSwitch },

  mixins: [validation],

  props: {
    deposit: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    isEditable: {
      type: Boolean,
      default: false
    },
    revision: {
      type: Object,
      default: null
    }
  },

  i18nOptions: {},

  data: (vm) => ({
    editing: false,
    termsData: {
      ...ContractDeposit(cloneDeep(vm.deposit)).contractTermsModel.termsData
    }
  }),

  computed: {
    ...mapState('classifiers', ['classifiers']),
    ...mapState('deposits', ['depositContract', 'depositTermsHistory']),
    ...mapGetters('classifiers', ['classifierByName', 'classifierById', 'optionsFromClassifier']),
    ...mapGetters('customers', ['isCorporateCustomer']),
    saving () {
      return this.$vueLoading.isLoading(['deposits:contract:save', `deposits:contract:revision:${this.revisionId}:save`])
    },
    isActive () {
      return this.deposit.status.code === loanStatuses.active
    },
    amountPeriod () {
      return this.deposit.terms.amountRangePeriodInterests[this.amountPeriodIdx]
    },
    amountPeriodIdx () {
      const { terms: { amount, amountRangePeriodInterests } } = this.deposit
      return amountRangePeriodInterests.findIndex(({ amountRange }) => amount >= amountRange.from && amount <= amountRange.to)
    },
    productPeriodUnitType () {
      const { classifier, code } = this.deposit.terms?.period?.unitType
      return this.classifierByName(classifier, code)?.human
    },
    revisionData () {
      return {
        ...pick(this.termsData, ['interests', 'originOfMoney', 'beneficiary', 'withholdTax']),
        autoExtendable: {
          additionalInterest: this.termsData.additionalInterest,
          extendable: this.termsData.autoExtendableEnabled
        }
      }
    },
    revisions () {
      return orderBy(this.depositTermsHistory, ['validUntil', 'id'], ['desc', 'desc'])
    },
    activeRevision () {
      return this.revisions.find(({ status }) => status.code === loanStatuses.active)
    },
    isCompoundInterestPossible () {
      return this.product.compoundInterest
    },
    isCompoundInterestDisabled () {
      const { interestPaymentMethod } = this.deposit.terms
      const { autoExtendableEnabled } = this.termsData
      return this.isActive || (!autoExtendableEnabled && (interestPaymentMethod.type.code === 'START_OF_PERIOD' || interestPaymentMethod.type.code === 'END_OF_PERIOD'))
    },
    revisionId () {
      return this.revision.id
    },
    isActiveRevision () {
      return this.revision?.status?.code === loanStatuses.active
    }
  },
  watch: {
    async '$v.termsData.withholdTax.residenceCountryId.$model' (value) {
      if (this.$v.termsData.withholdTax.enabled.$model) {
        if (value) {
          await this.classifiers.TAXATION_COUNTRIES.forEach((current) => {
            if (current.countryDto.id === value && current.default) {
              this.$v.termsData.withholdTax.rate.$model = current.rate
            }
          })
        }
      }
    }
  },

  validations () {
    return ContractDeposit(this.deposit).contractTermsModel.validations
  },

  async created () {
    await this.loadTaxationCountries()
  },

  methods: {
    ...mapActions('deposits', [
      'updateDepositContract',
      'saveDepositTermsRevision',
      'syncDepositContractWithProduct',
      'loadDepositContractByRevision'
    ]),
    ...mapActions('classifiers', ['loadTaxationCountries']),
    optionsFromClassifierTaxCountry () {
      const options = []
      this.optionsFromClassifier('taxationCountries').forEach((current) => {
        if (!options.some(option => option.value === current.value.id)) {
          options.push({ text: current.value.name, value: current.value.id })
        }
      })
      return options
    },
    optionsFromClassifierTax (id) {
      const options = []
      this.optionsFromClassifier('taxationCountries').forEach((current) => {
        if (current.value.id === id) {
          options.push({ text: `${(current.text) * 100} %`, value: current.text })
        }
      })
      return options
    },
    resetData () {
      Object.assign(this.$data, this.$options.data.call(this))
      this.$v.$reset()

      // Parse from string to array of incomeSources
      this.$v.termsData.originOfMoney.$model = this.$v.termsData.originOfMoney.$model ? this.$v.termsData.originOfMoney.$model.split(', ')
        .map((el) => this.optionsFromClassifier('incomeSources')
          .find((income) => income.text === el.toLowerCase()))
        .filter((el) => !!el) : []
    },
    async submit () {
      if (this.validate()) {
        if (this.revisionId) {
          await this.saveDepositTermsRevision({
            contractId: this.deposit.id,
            revisionId: this.revisionId,
            revisionData: {
              ...this.revisionData,
              originOfMoney: this.revisionData.originOfMoney.map(el => el.text.toUpperCase()).join(', ')
            }
          })
        } else {
          await this.updateDepositContract({
            contractData: {
              ...this.termsData,
              originOfMoney: this.termsData.originOfMoney.map(el => el.text.toUpperCase()).join(', ')
            }
          })
        }
        this.toggleEditing({ status: false })
      }
    },
    toggleEditing ({ status = !this.editing } = {}) {
      if (status) {
        this.resetData()
      }
      this.editing = status
    },
    async runSync ({ done }) {
      await this.syncDepositContractWithProduct()

      if (this.depositContract?.id && this.activeRevision?.id) {
        await this.loadDepositContractByRevision({
          contractId: this.depositContract.id,
          revisionId: this.activeRevision.id
        })
      }

      done()
    }
  }
}
</script>


<i18n>
en:
  title:              "Deposit terms"
  compoundInterest:   "Compound interest"
  autoExtend:         "Extend automatically"
  additionalInterest: "Additional interest"
  withholdIncomeTax:  "Withhold income tax and rate"
  taxResidency:       "Residency for tax purposes"
  moneyOrigin:        "Origin of money"
  beneficiary:        "Beneficiary"
  amount:             "Amount"
  from:               "From"
  to:                 "To"
  interestPeriods:    "Interest periods"
  interest:           "Interest"
  sync:               "Sync with product"
  syncConfirm:        "Sync with product terms?"
et:
  title:              "Deposit terms"
  compoundInterest:   "Compound interest"
  autoExtend:         "Extend automatically"
  additionalInterest: "Additional interest"
  withholdIncomeTax:  "Withhold income tax and rate"
  taxResidency:       "Residency for tax purposes"
  moneyOrigin:        "Origin of money"
  beneficiary:        "Beneficiary"
  amount:             "Amount"
  from:               "From"
  to:                 "To"
  interestPeriods:    "Interest periods"
  interest:           "Interest"
  sync:               "Sync with product"
  syncConfirm:        "Sync with product terms?"
ru:
  title:              "Deposit terms"
  compoundInterest:   "Compound interest"
  autoExtend:         "Extend automatically"
  additionalInterest: "Additional interest"
  withholdIncomeTax:  "Withhold income tax and rate"
  taxResidency:       "Residency for tax purposes"
  moneyOrigin:        "Origin of money"
  beneficiary:        "Beneficiary"
  amount:             "Amount"
  from:               "From"
  to:                 "To"
  interestPeriods:    "Interest periods"
  interest:           "Interest"
  sync:               "Sync with product"
  syncConfirm:        "Sync with product terms?"
</i18n>
