<template lang='pug'>
fi-modal.deposit-withdrawal-modal(
  ref        = 'modal'
  v-model    = 'showModal'
  :title     = '$t("title")'
  size       = 'lg'
  lazy
  @hide      = 'onHide'
  @shown     = 'resetData'
)
  form.form-horizontal(@submit.prevent = 'onSubmit')
    fi-form-field(:label = '$t("withdrawalAmount")')
      fi-money-input.form-control.form-control-sm(v-model = 'withdrawalData.amount')
    fi-form-field(:label = '$t("withdrawalFee")')
      .form-row
        .col: fi-select(
          v-model   = 'withdrawalData.feeCalculationType'
          :options  = 'calculationTypes'
          :disabled = '!withdrawalFeeProduct.feeOverride'
          required
          sm
        )
        .col
          percent-input.form-control.form-control-sm(
            v-if           = 'percentageWithdrawalFee'
            v-model.number = 'withdrawalData.feeAmount'
          )
          fi-money-input.form-control.form-control-sm(
            v-else
            v-model.number = 'withdrawalData.feeAmount'
          )
  fi-table(
    :fields = 'fields'
    :items  = 'withdrawalData.relatedPayments'
  )
    template(#default = '{ items }')
      tr(v-for = '(item, index) in items')
        td {{ item.beneficiaryName }}
        td {{ classifierById('paymentWays', item.paymentWayId).human }}
        td {{ item.beneficiaryExternalAccountNumber }}
        td.money {{ item.amount | money }}
        td
          button.btn.btn-sm.btn-link(@click.prevent = 'editPayout(index)'): i.far.fa-edit.fa-lg
  template(#modal-footer)
    button.btn.btn-primary(
      :disabled    = 'loader || !withdrawalData.amount'
      v-fi-confirm = '{ ok: onSubmit }'
    ) {{ $t("common:save") }}
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'loader')
    button.btn.btn-secondary(
      :disabled      = 'loader'
      @click.prevent = '$refs.modal.hide()'
    ) {{ $t("common:cancel") }}
  loan-payout-relation-modal(
    v-if              = 'showPayoutModal'
    v-model           = 'showPayoutModal'
    :payment-relation = 'currentRelation'
    :callback         = 'updatePayouts'
  )
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiMoneyInput from '@/components/FiMoneyInput'
import { calculationTypes, convertFee, convertFeeRange, feePercentage } from '@/helpers'
import { mapGetters } from 'vuex'
import FiSelect from '@/components/FiSelect'
import PercentInput from '@/components/PercentInput'
import LoanPayoutRelationModal from '@/views/loans/LoanPayoutRelationModal'
import FiModal from '@/components/FiModal'
import FiTable from '@/components/FiTable'

export default {
  name: 'deposit-withdrawal-modal',

  components: { FiTable, FiModal, LoanPayoutRelationModal, PercentInput, FiSelect, FiMoneyInput, FiFormField },

  props: {
    value: {
      type: Boolean,
      required: true
    },
    okAction: {
      type: Function,
      default: null
    },
    loader: {
      type: Boolean,
      required: true
    },
    deposit: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    paymentRelations: {
      type: Array,
      required: true
    },
    feeConfigs: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  data: () => ({
    showPayoutModal: false,
    withdrawalData: {
      amount: undefined,
      feeAmount: '',
      feeCalculationType: '',
      relatedPayments: []
    }
  }),

  computed: {
    ...mapGetters('classifiers', ['optionsFromClassifier', 'classifierById', 'classifierList']),
    ...mapGetters('settings', ['settingByKey']),
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    calculationTypes () {
      return calculationTypes(
        this.withdrawalFee,
        this.feeConfigs,
        this.classifierList('feeCalculationType')
      )
    },
    withdrawalFee () {
      return this.deposit.terms.fees.find(({ feeType }) => feeType.code === 'WITHDRAWAL') ?? {}
    },
    withdrawalFeeProduct () {
      const { fees = [] } = this.product
      return fees.find(({ feeType }) => feeType.code === 'WITHDRAWAL') || {}
    },
    percentageWithdrawalFee () {
      return feePercentage(this.withdrawalData.feeCalculationType)
    },
    convertedWithdrawalFeeRange () {
      const { currentBalance } = this.deposit
      return convertFeeRange({ ...this.withdrawalFeeProduct, feeCalculationType: this.withdrawalData.feeCalculationType }, currentBalance)
    },
    fields () {
      return [
        {
          label: this.$t('beneficiaryName'),
          key: 'beneficiaryName'
        },
        {
          label: this.$t('paymentWay'),
          key: 'paymentWayId'
        },
        {
          label: this.$t('beneficiaryIBAN'),
          key: 'beneficiaryExternalAccountNumber'
        },
        {
          label: this.$t('amount'),
          key: 'amount',
          class: 'money'
        },
        {
          key: 'actions'
        }
      ]
    }
  },

  watch: {
    percentageWithdrawalFee (val, oldVal) {
      if (typeof oldVal === 'undefined' || val === oldVal) {
        return
      }

      this.withdrawalData.feeAmount = convertFee({
        feeValue: this.withdrawalData.feeAmount,
        isPercentage: val,
        amountValue: this.deposit.currentBalance
      })
    },
    'withdrawalData.amount': {
      handler (amount) {
        this.withdrawalData.relatedPayments = this.withdrawalData.relatedPayments.map(payment => ({
          ...payment,
          amount
        }))
      },
      immediate: true
    }
  },

  methods: {
    resetData () {
      Object.assign(this.$data, this.$options.data.apply(this))
      this.withdrawalData = {
        ...this.withdrawalData,
        feeAmount: this.withdrawalFee.feeAmount,
        feeCalculationType: this.withdrawalFee.feeCalculationType.code,
        relatedPayments: this.paymentRelations
      }
    },
    onHide (e) {
      if (this.loader) {
        e.preventDefault()
      }
    },
    editPayout (index) {
      this.currentRelation = { ...this.withdrawalData.relatedPayments[index], id: index + 1 }
      this.showPayoutModal = true
    },
    updatePayouts (payout) {
      this.withdrawalData.relatedPayments = [payout]
    },
    async onSubmit ({ done }) {
      const { relatedPayments } = this.withdrawalData
      await this.okAction({ ...this.withdrawalData, relatedPayments: relatedPayments.map(({ id, ...payment }) => payment) })
      done()
      this.$refs.modal.hide()
    }
  }
}
</script>


<i18n>
en:
  title:            "Deposit withdrawal"
  withdrawalAmount: "Withdrawal amount"
  withdrawalFee:    "Withdrawal fee"
  payoutAccount:    "Payout bank account"
  paymentWay:       "Payment way"
  payableAfterTime: "Payable after time"
  beneficiaryName:  "Beneficiary name"
  beneficiaryIBAN:  "Beneficiary IBAN"
  amount:           "Amount"
  payoutRelations:  "Payouts"
et:
  title:            "Deposit withdrawal"
  withdrawalAmount: "Withdrawal amount"
  withdrawalFee:    "Withdrawal fee"
  payoutAccount:    "Payout bank account"
  paymentWay:       "Payment way"
  payableAfterTime: "Payable after time"
  beneficiaryName:  "Beneficiary name"
  beneficiaryIBAN:  "Beneficiary IBAN"
  amount:           "Amount"
  payoutRelations:  "Payouts"
ru:
  title:            "Deposit withdrawal"
  withdrawalAmount: "Withdrawal amount"
  withdrawalFee:    "Withdrawal fee"
  payoutAccount:    "Payout bank account"
  paymentWay:       "Payment way"
  payableAfterTime: "Payable after time"
  beneficiaryName:  "Beneficiary name"
  beneficiaryIBAN:  "Beneficiary IBAN"
  amount:           "Amount"
  payoutRelations:  "Payouts"
</i18n>
