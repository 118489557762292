<template lang = 'pug'>
.deposit-list.animated.fadeIn
  .card
    .card-header {{ $t('menu:deposits') }}
      router-link.float-right(
        to = 'new'
        append
      ) {{ $t('menu:depositNew') }}
    .card-body
      form.form-horizontal
        .form-row
          .form-group.col-lg-6
            .input-group
              .input-group-prepend: span.input-group-text: i.fa.fa-search
              input.form-control(
                v-model.trim = 'searchParams.search'
                :placeholder = '$t("searchPlaceholder")'
                type         = 'text'
              )
          .form-group.col-lg-6
            range-input-date(
              :options     = 'rangeInputDateOptions'
              :start.sync  = 'searchParams.dateMin'
              :end.sync    = 'searchParams.dateMax'
              :column.sync = 'searchParams.dateColumn'
            )
        .form-row
          .form-group.col-lg-6
            range-input-text(
              :title       = '$t("amount")'
              :start.sync  = 'searchParams.amountMin'
              :end.sync    = 'searchParams.amountMax'
            )
          .form-group.col-lg-3
            .input-group
              .input-group-prepend: span.input-group-text: i.fa.fa-tag
              fi-select(
                v-model.number = 'searchParams.productId'
                :options       = 'optionsFromProducts'
                :placeholder   = '$t("productsPlaceholder")'
              )
          .form-group.col-lg-3
            .input-group
              .input-group-prepend: span.input-group-text: i.fa.fa-flag
              fi-multiselect(
                v-model      = 'statusSearchModel'
                :options     = 'statusOptions'
                :placeholder = '$t("statusesPlaceholder")'
                multiple
              )
      .my-2(v-if = 'depositTotal') {{ $t('searchResults', { results: depositTotal }) }}
      fi-table(
        :fields      = 'fields'
        :items       = 'deposits'
        loader       = 'deposits:fetch'
        :empty-label = '$t("notFound")'
        :sort.sync   = 'searchParams.sort'
        responsive
      )
        template(#default = '{ items }')
          deposit-list-item.animated.fadeIn(
            v-for    = 'deposit in items'
            :key     = 'deposit.id'
            :deposit = 'deposit'
            @set-manager = 'setManager'
          )
      fi-pagination(
        v-if    = 'depositPages > 1'
        :pages  = 'depositPages'
        v-model = 'pagination.page'
      )
</template>


<script>
import FiPagination from '@/components/FiPagination'
import FiSelect from '@/components/FiSelect'
import FiTable from '@/components/FiTable'
import RangeInputDate from '@/components/RangeInputDate'
import RangeInputText from '@/components/RangeInputText'
import Template from '@/views/admin/templates/Template'
import DepositListItem from '@/views/deposits/DepositListItem'
import debounce from 'lodash/debounce'
import pickBy from 'lodash/pickBy'
import pick from 'lodash/pick'
import { mapActions, mapGetters, mapState } from 'vuex'
import { pagination } from '@/const'
import FiMultiselect from '@/components/FiMultiselect'
import { getStatuesQuery } from '@/helpers'

const searchParams = {
  search: undefined,
  dateMin: '',
  dateMax: '',
  dateColumn: 'startDate',
  amountMin: '',
  amountMax: '',
  statuses: [],
  productId: '',
  sort: 'startDate,desc'
}

export default {
  name: 'deposit-list',

  components: {
    FiMultiselect,
    Template,
    DepositListItem,
    FiPagination,
    FiTable,
    FiSelect,
    RangeInputText,
    RangeInputDate
  },

  i18nOptions: {},

  data: (vm) => ({
    searchParams: { ...searchParams, ...pick(vm.$route.query, Object.keys(searchParams)), statuses: getStatuesQuery(vm.$route.query.statuses) },
    pagination: { ...pagination, ...pick(vm.$route.query, Object.keys(pagination)) }
  }),

  computed: {
    ...mapGetters('classifiers', ['optionsFromClassifier']),
    ...mapGetters('products', ['optionsFromProducts']),
    ...mapState('deposits', ['deposits', 'depositPages', 'depositTotal']),
    statusOptions () {
      return this.optionsFromClassifier('DepositContractStatusType')
    },
    statusSearchModel: {
      get () {
        return this.searchParams.statuses.map(searchValue => this.statusOptions.find(({ value }) => value === searchValue))
      },
      set (values) {
        if (values.length === this.statusOptions.length) {
          this.searchParams.statuses = []
        } else {
          this.searchParams.statuses = values.map(({ value }) => value)
        }
      }
    },
    searchFilter () {
      const filter = { ...this.searchParams }
      if (!(filter.dateMin || filter.dateMax)) {
        filter.dateColumn = null
      }
      if (!(filter.amountMin || filter.amountMax)) {
        filter.amountColumn = null
      }
      return pickBy(filter)
    },
    fields () {
      return [
        {
          key: 'contract',
          label: this.$t('contract'),
          sort: 'contractNumber'
        },
        {
          key: 'customer',
          label: this.$t('customer')
        },
        {
          key: 'product',
          label: this.$t('product')
        },
        {
          key: 'amount',
          label: this.$t('amount'),
          class: 'money text-wrap'
        },
        {
          key: 'balance',
          label: this.$t('balance'),
          class: 'money'
        },
        {
          key: 'interest',
          label: this.$t('interest'),
          class: 'money text-wrap'
        },
        {
          key: 'reference',
          label: this.$t('reference')
        },
        {
          key: 'startDate',
          label: this.$t('startDatePlaceholder'),
          sort: 'startDate'
        },
        {
          key: 'endDate',
          label: this.$t('endDatePlaceholder'),
          sort: 'endDate'
        },
        {
          key: 'nextPaymentDate',
          label: this.$t('nextPaymentDate')
        },
        {
          key: 'nextPayment',
          label: this.$t('nextPayment')
        },
        {
          key: 'autoExtendable',
          label: this.$t('autoExtendable')
        },
        {
          key: 'manager',
          label: this.$t('manager')
        },
        {
          key: 'status',
          label: this.$t('status'),
          sort: 'statusType'
        }
      ]
    },
    searchQuery () {
      return pickBy({ ...this.searchFilter, ...this.pagination })
    },
    rangeInputDateOptions () {
      return {
        startDate: this.$t('startDatePlaceholder'),
        endDate: this.$t('endDatePlaceholder'),
        nextPayoutDate: this.$t('nextPaymentDate')
      }
    }
  },

  watch: {
    searchParams: {
      handler () {
        this.pagination.page = 0
        this.search()
      },
      deep: true
    },
    'pagination.page': 'search',
    '$route.query': {
      handler (query) {
        this.loadDepositContracts({ filters: query })
      },
      deep: true
    }
  },

  created () {
    this.loadProducts({ group: 'DEPOSIT' })
    this.loadDepositContracts({ filters: this.searchQuery })
  },

  methods: {
    ...mapActions('products', ['loadProducts']),
    ...mapActions('deposits', ['loadDepositContracts', 'setDepositContractManager']),
    search: debounce(function () {
      this.$router.push({ query: this.searchQuery })
    }, 250),
    setManager (payload) {
      this.setDepositContractManager(payload)
      this.search()
    }
  }
}
</script>


<i18n>
en:
  searchPlaceholder:    "Search by name, id code, agreement reference"
  startDatePlaceholder: "Start date"
  endDatePlaceholder:   "End date"
  productsPlaceholder:  "All products"
  statusesPlaceholder:  "All deposit statuses"
  customer:             "Customer"
  contract:             "Contract"
  amount:               "Initial amount"
  balance:              "Balance"
  interest:             "Interest accrued"
  debt:                 "Service debt"
  reference:            "Reference"
  product:              "Product"
  status:               "Status"
  autoExtendable:       "Extend"
  manager:              "Manager"
  notFound:             "Deposits not found"
  nextPaymentDate:      "Next payment date"
  nextPayment:          "Next payment"
  searchResults:        "Search results: {{ results }}"
et:
  searchPlaceholder:    "Search by name, id code, agreement reference"
  startDatePlaceholder: "Start date"
  endDatePlaceholder:   "End date"
  productsPlaceholder:  "All products"
  statusesPlaceholder:  "All deposit statuses"
  customer:             "Customer"
  contract:             "Contract"
  amount:               "Initial amount"
  balance:              "Balance"
  interest:             "Interest accrued"
  debt:                 "Service debt"
  reference:            "Reference"
  product:              "Product"
  status:               "Status"
  autoExtendable:       "Extend"
  manager:              "Manager"
  notFound:             "Deposits not found"
  nextPaymentDate:      "Next payment date"
  nextPayment:          "Next payment"
  searchResults:        "Search results: {{ results }}"
ru:
  searchPlaceholder:    "Search by name, id code, agreement reference"
  startDatePlaceholder: "Start date"
  endDatePlaceholder:   "End date"
  productsPlaceholder:  "All products"
  statusesPlaceholder:  "All deposit statuses"
  customer:             "Customer"
  contract:             "Contract"
  amount:               "Initial amount"
  balance:              "Balance"
  interest:             "Interest accrued"
  debt:                 "Service debt"
  reference:            "Reference"
  product:              "Product"
  status:               "Status"
  autoExtendable:       "Extend"
  manager:              "Manager"
  notFound:             "Deposits not found"
  nextPaymentDate:      "Next payment date"
  nextPayment:          "Next payment"
  searchResults:        "Search results: {{ results }}"
</i18n>

