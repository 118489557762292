<template lang='pug'>
tr.deposit-revision-list-item(
  :class = '{ "table-active": active }'
  @click = 'emitSelect'
)
  td {{ $t('common:formatDate', { value: revision.validFrom }) }}
  td {{ $t('common:formatDate', { value: revision.validUntil }) }}
  td.text-nowrap
    .input-group(v-if = '(status.name === "PENDING") && active')
      input.form-control.form-control-sm(
        v-model.trim = 'reasonModel'
        @click.stop
      )
      .input-group-append
        button.btn.btn-primary.p-1(
          v-if = 'value.$dirty'
          @click.stop = '$emit("reason", value.$model)'
        ) {{ $t('common:save') }}
          i.fa.fa-floppy-o.ml-1
          i.fa.fa-spinner.fa-pulse.ml-1(v-if = '$vueLoading.isLoading("deposits:terms:revision:reason:save")')

    template(v-else) {{ revision.comment }}
  td {{ revision.updatedBy }}
  td: .badge(:class = '`badge-${statusClass}`') {{ status.human }}
</template>

<script>
import FiFormField from '@/components/FiFormField'
import { mapGetters } from 'vuex'

const COLOR_MAP = {
  PENDING: 'primary',
  COMPLETE: 'secondary',
  ACTIVE: 'success'
}

export default {
  name: 'deposit-revision-list-item',

  components: { FiFormField },

  props: {
    revision: {
      type: Object,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    }
  },


  computed: {
    ...mapGetters('classifiers', ['classifierById']),
    reasonModel: {
      get () {
        return this.value.$model
      },
      set (reason) {
        this.$emit('input', reason)
      }
    },
    status () {
      return this.classifierById(this.revision.status.classifier, this.revision.status.code)
    },
    statusClass () {
      return COLOR_MAP[this.revision.status.code]
    }
  },

  watch: {
    revision: {
      handler () {
        this.emitSelect()
      },
      deep: true
    }
  },

  methods: {
    emitSelect () {
      this.$emit('select', this.revision)
    }
  }
}
</script>

<style scoped lang='scss'>
.deposit-revision-list-item {
  cursor: pointer;
}
</style>
