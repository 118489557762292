<template lang='pug'>
.deposit-creation-data.card
  .card-header {{ $t('title') }}
  .card-body
    fi-data-field(:label = '$t("source")') {{ deposit.source }}
    fi-data-field(:label = '$t("creator")')
      router-link(:to = '{ name: "Customer", params: { id: deposit.createdBy } }') {{ creator.name }}
</template>


<script>
import { mapGetters } from 'vuex'
import FiDataField from '@/components/FiDataField'

export default {
  name: 'deposit-creation-data',

  components: { FiDataField },

  props: {
    deposit: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  computed: {
    ...mapGetters('classifiers', ['managerById']),
    creator () {
      return this.managerById(this.deposit.createdBy)
    }
  }
}
</script>


<i18n>
en:
  title: "Creation data"
  source: "Source"
  creator: "Creator"
et:
  title: "Creation data"
  source: "Source"
  creator: "Creator"
ru:
  title: "Creation data"
  source: "Source"
  creator: "Creator"
</i18n>

