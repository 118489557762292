<template lang='pug'>
.deposit-view
  v-loading(loader='deposits:contract:fetch')
    template(#spinner)
      .text-center: i.fa.fa-spinner.fa-pulse.fa-lg
    .animated.fadeIn(v-if = 'depositContract')
      .deposit-view-header
        h3.header
          .header-item: router-link(:to = '{ name: "Customer", params: { id: depositContract.customer.id.toString() } }') {{ fullName }}
          .header-item: router-link(:to = '{  name: "Deposit", params: { id: this.id.toString() }}')
            v-loading.d-inline-block(loader = 'deposits:product:fetch')
              template(#spinner)
                i.fa.fa-spinner.fa-pulse.fa-lg
              template(v-if = 'depositProduct') {{ depositProduct.name }} ({{ depositContract.contractNumber }})
          .header-item: .badge(:class = '`badge-${loanStatusColor(status.name)}`') {{ status.human }}
        .row
          .col
            ul.nav.nav-pills.mt-2.mb-2
              li.nav-item: router-link.nav-link(:to = '{ name: "DepositOverview" }') {{ $t('overview') }}
              li.nav-item: router-link.nav-link(:to = '{ name: "DepositTerms" }') {{ $t('terms') }}
              template(v-if = 'isShowFinancials')
                li.nav-item: router-link.nav-link(:to = '{ name: "DepositFinancials" }') {{ $t('financials') }}
              li.nav-item: router-link.nav-link(:to = '{ name: "DepositCommunication" }') {{ $t('communication') }}
          v-loading.col-auto(loader = 'deposits:product:fetch')
            template(#spinner)
              i.fa.fa-spinner.fa-pulse.fa-lg
            .btn-toolbar
              .btn-group
                button.animated.fadeIn.btn.btn-outline-primary(@click = 'showSendMessage("DepositNotificationEmailTemplate")')
                  i.far.fa-bell.fa-fw
                button.animated.fadeIn.btn.btn-outline-primary(@click = 'showSendMessage("DepositContractEmailTemplate")')
                  i.far.fa-envelope.fa-fw
                  |&nbsp;
                  i.fa.fa-print.fa-fw
      router-view
      loan-send-message-modal(
        v-if     = 'showSendMessageModal'
        v-model      = 'showSendMessageModal'
        :contract    = 'depositContract'
        :product     = 'depositProduct'
        :type        = 'templateType'
        store-module = 'deposits'
        :customer    = 'depositCustomer'
        :schedule    = 'activeRevision'
        :schedules   = 'depositTermsHistory'
      )

</template>


<script>
import LoanSendMessageModal from '@/views/loans/LoanSendMessageModal'
import { mapActions, mapGetters, mapState } from 'vuex'
import { loanStatuses } from '@/const'
import { customerName } from '@/helpers'

export default {
  name: 'deposit-view',
  components: { LoanSendMessageModal },
  props: {
    id: {
      type: [Number, String],
      default: undefined
    }
  },

  i18nOptions: {},

  data: () => ({
    showSendMessageModal: false,
    templateType: ''
  }),

  computed: {
    ...mapState('deposits', ['depositContract', 'depositProduct', 'depositCustomer', 'depositTermsHistory']),
    ...mapGetters('loans', ['loanStatusColor']),
    ...mapGetters('classifiers', ['classifierByName']),
    fullName () {
      const { idCode } = this.depositContract?.customer || {}
      return `${customerName(this.depositContract?.customer)} (${idCode})`
    },
    status () {
      const { classifier, code } = this.depositContract?.status || {}
      return this.classifierByName(classifier, code) ?? {}
    },
    isShowFinancials () {
      return ['ACTIVE', 'MATURED', 'TERMINATED'].includes(this.depositContract?.status?.code)
    },
    activeRevision () {
      return this.depositTermsHistory.find(({ status }) => status.code === loanStatuses.active)
    }
  },

  async created () {
    this.loadDepositTermsRevisionsClassifiers({ contractId: this.id })
    await this.loadDepositContract({ contractId: this.id })
    await this.loadDepositCustomer()
    this.loadDepositProduct()
    this.loadManagers()
    this.loadDepositContractAudit()
    this.loadTemplates()
    if (this.isShowFinancials) {
      this.loadDepositHistory({ contractId: this.id })
    }
  },

  methods: {
    ...mapActions('deposits', [
      'loadDepositContract',
      'loadDepositProduct',
      'loadDepositContractAudit',
      'loadDepositContractFinancials',
      'loadDepositCustomer',
      'loadDepositHistory'
    ]),
    ...mapActions('classifiers', ['loadManagers', 'loadDepositTermsRevisionsClassifiers']),
    ...mapActions('templates', ['loadTemplates']),
    showSendMessage (type) {
      this.templateType = type
      this.showSendMessageModal = true
    }
  }
}
</script>


<i18n>
en:
  overview:      "Overview"
  communication: "Communication"
  financials:    "Financials"
  terms:         "Terms"
et:
  overview:      "Overview"
  communication: "Communication"
  financials:    "Financials"
  terms:         "Terms"
ru:
  overview:      "Overview"
  communication: "Communication"
  financials:    "Financials"
  terms:         "Terms"
</i18n>
