<template lang="pug">
.card.deposit-payout
  .card-header {{ $t('title') }}
    button.btn.btn-link.fa-pull-right.animated.fadeIn.py-0(
      v-if           = '!editing && isEditable'
      :title         = '$t("common:edit")'
      @click.prevent = 'toggleEditing'
    ): i.far.fa-edit.fa-lg
  .card-body
    form.form.animated.fadeIn(
      v-if            = 'editing'
      @submit.prevent = 'savePaymentRelation'
      novalidate
    )
      fi-form-field(:label = '$t("paymentDestination")')
        fi-select(
          v-model  = 'paymentRelation.paymentWayId'
          :class   = '{ "is-invalid": paymentRelation.paymentWayId.$error }'
          :options = 'optionsFromClassifier("paymentWays")'
          sm
          required
        )
      fi-form-field(:label = '$t("beneficiaryName")')
        fi-multiselect(
          v-model          = 'selectedCustomerModel'
          :options         = 'systemCustomersForSelect'
          :search-callback = 'findSystemCustomers'
          :loading         = '$vueLoading.isLoading("loans:systemCustomers:fetch")'
        )
        input.form-control-sm.form-control(
          v-model   = 'paymentRelation.beneficiaryName'
          :disabled = '!!paymentRelation.customerId'
        )
      fi-form-field(:label = '$t("payoutAccount")')
        fi-select(
          v-if     = 'paymentRelation.customerId && bankAccountOptions.length'
          v-model  = 'paymentRelation.beneficiaryExternalAccountNumber'
          :options = 'bankAccountOptions'
          sm
          required
        )
        input.form-control-sm.form-control(v-model = 'paymentRelation.beneficiaryExternalAccountNumber')
      fi-form-field(:label = '$t("description")')
        input.form-control.form-control-sm(v-model = 'paymentRelation.description')
      button.btn.btn-primary.mr-sm-2(
        :disabled = 'saving'
        type      = 'submit'
      )
        | {{ $t('common:save') }}
        i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
      button.btn.btn-secondary(
        :disabled      = 'saving'
        @click.prevent = 'toggleEditing'
      ) {{ $t('common:cancel') }}
    template(v-else)
      fi-data-field(:label = '$t("paymentDestination")') {{ classifierById('paymentWays', relatedPayment.paymentWayId).human }}
      fi-data-field(:label = '$t("beneficiaryName")') {{ relatedPayment.beneficiaryName }}
      fi-data-field(:label = '$t("payoutAccount")') {{ relatedPayment.beneficiaryExternalAccountNumber }}
      fi-data-field(:label = '$t("description")') {{ relatedPayment.description }}
</template>


<script>
import FiDataField from '@/components/FiDataField'
import { mapActions, mapGetters, mapState } from 'vuex'
import FiFormField from '@/components/FiFormField'
import FiSelect from '@/components/FiSelect'
import pick from 'lodash/pick'
import FiMultiselect from '@/components/FiMultiselect'

export default {
  name: 'deposit-payout',

  components: { FiMultiselect, FiSelect, FiFormField, FiDataField },

  props: {
    relatedPayment: {
      type: Object,
      required: true
    },
    isEditable: {
      type: Boolean,
      default: false
    }
  },

  data: (vm) => ({
    editing: false,
    paymentRelation: { ...vm.relatedPayment }
  }),

  computed: {
    ...mapGetters('classifiers', ['classifierById', 'optionsFromClassifier']),
    ...mapState('loans', ['systemCustomer']),
    ...mapGetters('loans', ['systemCustomersForSelect']),
    ...mapGetters('customers', ['customerName']),
    saving () {
      return this.$vueLoading.isLoading('deposit:paymentRelation:save')
    },
    selectedCustomerModel: {
      get () {
        return this.paymentRelation.customerId ? {
          text: `${this.paymentRelation.beneficiaryName} (${this.paymentRelation.beneficiaryIdCode})`,
          value: this.paymentRelation.customerId
        } : null
      },
      set (selected) {
        if (!selected) return
        this.selectCustomer(selected)
      }
    },
    bankAccountOptions () {
      const { externalBankAccounts = [], accounts = [] } = this.systemCustomer ?? {}
      return (this.classifierById('paymentWays', this.paymentRelation.paymentWayId).name === 'external'
        ? externalBankAccounts.map(({ accountExternalNumber }) => accountExternalNumber)
        : accounts.filter(({ accountTypeId }) => /prepayment/i.test(this.classifierById('accountTypes', accountTypeId).name))
          .map(({ number }) => number)) ?? []
    }
  },

  watch: {
    'paymentRelation.paymentWayId' () {
      this.paymentRelation.beneficiaryExternalAccountNumber = this.bankAccountOptions?.[0] ?? ''
    }
  },

  methods: {
    ...mapActions('loans', ['findSystemCustomers', 'loadSystemCustomer']),
    ...mapActions('deposits', ['saveDepositPaymentRelation', 'createDepositPaymentRelation']),
    resetData () {
      Object.assign(this.$data, this.$options.data.call(this, this))
      this.selectCustomer({ value: this.relatedPayment?.customerId })
    },
    toggleEditing ({ status = !this.editing } = {}) {
      if (status) {
        this.resetData()
      }
      this.editing = status
    },
    async selectCustomer ({ value }) {
      await this.loadSystemCustomer({ id: value })

      this.paymentRelation = {
        ...pick(this.relatedPayment, Object.keys(this.paymentRelation)),
        ...this.paymentRelation,
        ...(this.systemCustomer ? {
          beneficiaryName: this.customerName(this.systemCustomer),
          beneficiaryExternalAccountNumber: this.relatedPayment?.beneficiaryExternalAccountNumber || this.bankAccountOptions[0],
          beneficiaryIdCode: this.systemCustomer?.idCode,
          customerId: this.systemCustomer?.id
        } : {})
      }
    },
    async savePaymentRelation () {
      if (!this.relatedPayment.relationId) {
        await this.createDepositPaymentRelation({ paymentRelationData: this.paymentRelation })
      } else {
        await this.saveDepositPaymentRelation({ paymentRelationData: this.paymentRelation, paymentRelationId: this.relatedPayment.relationId })
      }
      this.editing = false
    }
  }
}
</script>


<i18n>
en:
  title:              "Deposit payout"
  paymentDestination: "Payment destination"
  payoutAccount:      "Payout bank account number"
  beneficiaryName:    "Beneficiary name"
  description:        "Description"
et:
  title:              "Deposit payout"
  paymentDestination: "Payment destination"
  payoutAccount:      "Payout bank account number"
  beneficiaryName:    "Beneficiary name"
  description:        "Description"
ru:
  title:              "Deposit payout"
  paymentDestination: "Payment destination"
  payoutAccount:      "Payout bank account number"
  beneficiaryName:    "Beneficiary name"
  description:        "Description"
</i18n>
