<template lang='pug'>
  .deposit-details.card
    .card-header {{ $t('title') }}
      button.btn.btn-link.fa-pull-right.animated.fadeIn.py-0(
        v-if           = '!editing && isEditable'
        :title         = '$t("common:edit")'
        @click.prevent = 'toggleEditing'
      ): i.far.fa-edit.fa-lg
    .card-body
      form.form.animated.fadeIn(
        v-if            = 'editing'
        @submit.prevent = 'submit'
        novalidate
      )
        fi-form-field(
          :label = '$t("amountInitial")'
          :state = '!$v.termsData.amount.$error'
        )
          template(#error)
            .error-message(v-if = '!$v.termsData.amount.required') {{ $t('common:required') }}
            .error-message(v-if = '!$v.termsData.amount.max') {{ $t('lte', { value: $v.termsData.amount.$params.max.max }) }}
            .error-message(v-if = '!$v.termsData.amount.min') {{ $t('gte', { value: $v.termsData.amount.$params.min.min }) }}
          fi-money-input.form-control-sm.form-control(
            v-model = '$v.termsData.amount.$model'
            :class  = '{ "is-invalid": $v.termsData.amount.$error }'
          )
        fi-form-field(:label = '$t("interestRate")')
          .form-row
            .col
              .form-control-plaintext {{ deposit.terms.finalInterestRate | percent }}
            .col
              fi-select(
                v-model  = '$v.termsData.dayCountConventionType.$model'
                :options = 'optionsFromClassifier("dayCountConvention")'
                :class   = '{ "is-invalid": $v.termsData.dayCountConventionType.$error }'
                required
                sm
              )
        fi-form-field(:label = '$t("interestFreq")')
          fi-select(
            v-model  = '$v.termsData.interestPaymentMethod.$model'
            :options = 'paymentMethods'
            required
            sm
          )
        fi-form-field(:label = '$t("dayBalanceCalc")')
          .form-control-plaintext {{ dailyBalanceCalculation.human }}
        fi-form-field(:label = '$t("period")')
          .form-row
            .col
              input.form-control.form-control-sm(v-model = '$v.termsData.periodQuantity.$model')
            .col
              fi-select(
                v-model  = '$v.termsData.periodUnitType.$model'
                :options = 'optionsFromClassifier(deposit.terms.period.unitType.classifier, true)'
                :class   = '{ "is-invalid": $v.termsData.periodUnitType.$error }'
                required
                sm
              )
        fi-form-field(:label    = '$t("startDate")')
          fi-datepicker(
            v-model   = '$v.termsData.startDate.$model'
            :min-date = 'systemData.nextDayChange'
          )
        fi-form-field(:label = '$t("endDate")')
          .form-control-plaintext {{ $t('common:formatDate', { value: endDate }) }}
        deposit-details-fee-item(
          v-for         = '(fee, index) in $v.termsData.fees.$each.$iter'
          :key          = 'index'
          :fee          = 'fee'
          :amount       = '$v.termsData.amount.$model'
          :fee-configs  = 'feeConfigs'
          editing
        )
        fi-form-field(
          v-if   = '!isProductFixed'
          :label = '$t("withdrawalAmount")'
          :state = '!$v.termsData.maxWithdrawalFromCurrentAmount.$error'
        )
          template(#error)
            .error-message(v-if = '!$v.termsData.maxWithdrawalFromCurrentAmount.required') {{ $t('common:required') }}
          percent-input.form-control.form-control-sm(
            v-model = '$v.termsData.maxWithdrawalFromCurrentAmount.$model'
            :class  = '{ "is-invalid": $v.termsData.maxWithdrawalFromCurrentAmount.$error }'
          )
        button.btn.btn-primary.mr-sm-2(
          :disabled = 'saving'
          type      = 'submit'
        )
          | {{ $t('common:save') }}
          i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
        button.btn.btn-secondary(
          :disabled      = 'saving'
          @click.prevent = 'toggleEditing'
        ) {{ $t('common:cancel') }}

      template(v-else)
        fi-data-field(:label = '$t("amountInitial")') {{ deposit.terms.amount | money }}
        fi-data-field(:label = '$t("interestRate")')
          .row
            .col {{ deposit.terms.finalInterestRate | percent }}
            .col {{ dayCountConvention.human }}
        fi-data-field(:label = '$t("interestFreq")') {{ interestFrequency.human }}
        fi-data-field(:label = '$t("dayBalanceCalc")') {{ dailyBalanceCalculation.human }}
        fi-data-field(:label = '$t("period")') {{ period }}
        fi-data-field(:label = '$t("startDate")') {{ $t('common:formatDate', { value: deposit.terms.startDate }) }}
        fi-data-field(:label = '$t("endDate")') {{ $t('common:formatDate', { value: deposit.terms.endDate }) }}
        deposit-details-fee-item(
          v-for       = '(fee, index) in deposit.terms.fees'
          :key        = 'index'
          :fee        = 'fee'
          :amount     = 'deposit.terms.amount'
          :fee-configs = 'feeConfigs'
        )
        fi-data-field(
          v-if   = '!isProductFixed'
          :label = '$t("withdrawalAmount")'
        ) {{ deposit.terms.maxWithdrawalFromCurrentAmount | percent}}
</template>


<script>
import FiDataField from '@/components/FiDataField'
import FiDatepicker from '@/components/FiDatepicker'
import FiFormField from '@/components/FiFormField'
import FiMoneyInput from '@/components/FiMoneyInput'
import FiSelect from '@/components/FiSelect'
import PercentInput from '@/components/PercentInput'
import { ContractDeposit } from '@/models'
import { validation, validators } from '@/validation'
import { mapActions, mapGetters, mapState } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'
import DepositDetailsFeeItem from '@/components/FiFeeItem'

export default {
  name: 'deposit-details',

  components: {
    DepositDetailsFeeItem,
    PercentInput,
    FiSelect,
    FiDatepicker,
    FiDataField,
    FiMoneyInput,
    FiFormField
  },

  mixins: [validation],

  props: {
    deposit: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    feeConfigs: {
      type: Object,
      default: () => ({})
    }
  },

  i18nOptions: {},

  data: (vm) => ({
    editing: false,
    termsData: ContractDeposit(cloneDeep(vm.deposit)).contractDetailsModel.termsData
  }),

  computed: {
    ...mapGetters('classifiers', ['classifierById', 'optionsFromClassifier', 'classifierByName']),
    ...mapGetters('settings', ['settingByKey']),
    ...mapState('loans', ['loan', 'systemData']),
    isEditable () {
      const { code } = this.deposit?.status || {}
      return !['ACTIVE', 'MATURED', 'CLOSED', 'TERMINATED'].includes(code)
    },
    saving () {
      return this.$vueLoading.isLoading('deposits:contract:save')
    },
    period () {
      const { quantity, unitType } = this.deposit.terms.period
      return this.$t(this.classifierByName(unitType.classifier, unitType.code)?.human, { count: quantity })
    },
    endDate () {
      const { quantity, unitType } = this.deposit.terms.period
      if (!this.editing) {
        return
      }
      return this.$moment(this.termsData.startDate).add(quantity, unitType.id)
    },
    paymentMethods () {
      const productPaymentMethodsIds = this.product?.interestPaymentMethods?.map(({ type }) => type.code)
      return this.optionsFromClassifier('interestPaymentMethod', true).filter(({ value }) => productPaymentMethodsIds?.includes(value))
    },
    interestFrequency () {
      const { code, classifier } = this.deposit.terms.interestPaymentMethod.type
      return this.classifierByName(classifier, code) ?? {}
    },
    dailyBalanceCalculation () {
      return this.classifierByName('DAILY_BALANCE_CALC_TYPE', this.product.dailyBalanceCalcType) ?? {}
    },
    dayCountConvention () {
      return this.classifierByName('dayCountConvention', this.deposit.terms.dayCountConventionType) ?? {}
    },
    isProductFixed () {
      return this.product.productType === 'FIXED_DEPOSIT'
    },
    maxProductAmount () {
      return Math.max(...Array.from(this.product.amountRangePeriodInterests || [], ({ amountRange }) => amountRange.to))
    },
    minProductAmount () {
      return Math.min(...Array.from(this.product.amountRangePeriodInterests || [], ({ amountRange }) => amountRange.from))
    }
  },

  validations () {
    return merge({},
      ContractDeposit(this.deposit).contractDetailsModel.validations,
      {
        termsData: {
          amount: {
            max: validators.maxValue(this.maxProductAmount),
            min: validators.minValue(this.minProductAmount)
          },
          maxWithdrawalFromCurrentAmount: {
            required: validators.requiredUnless(function () {
              return this.isProductFixed
            })
          }
        }
      }
    )
  },

  methods: {
    ...mapActions('deposits', ['updateDepositContract']),
    resetData () {
      Object.assign(this.$data, this.$options.data.call(this))
    },
    async submit () {
      if (this.validate()) {
        await this.updateDepositContract({
          contractData: {
            ...this.termsData,
            fees: this.termsData.fees.map(({ feeAmount, feeType, feeCalculationType }) => ({
              feeAmount,
              feeType: feeType.code,
              feeCalculationType: feeCalculationType.code
            }))
          }
        })
        this.toggleEditing({ status: false })
      }
    },
    toggleEditing ({ status = !this.editing } = {}) {
      if (status) {
        this.resetData()
      }
      this.editing = status
    }
  }
}
</script>


<i18n>
en:
  title:                  "Deposit details"
  amountInitial:          "Initial amount"
  amountCurrent:          "Current amount"
  period:                 "Deposit period"
  Day:                    "{{ count }} day"
  Day_plural:             "{{ count }} days"
  Week:                   "{{ count }} week"
  Week_plural:            "{{ count }} weeks"
  Month:                  "{{ count }} month"
  Month_plural:           "{{ count }} months"
  Year:                   "{{ count }} year"
  Year_plural:            "{{ count }} years"
  dayBalanceCalc:         "Daily balance calculation"
  startDate:              "Start date"
  endDate:                "End date"
  interestFreq:           "Interest frequency"
  interestRate:           "Interest rate"
  reference:              "Reference"
  payoutBankAccount:      "Payout bank account"
  moneyOrigin:            "Origin of money"
  beneficiary:            "Beneficiary"
  withholdIncomeTax:      "Withhold income tax and rate"
  taxResidency:           "Residency for tax purposes"
  countryPlaceholder:     "Select country"
  autoExtend:             "Extend automatically"
  terminationFee:         "Termination fee"
  withdrawalFee:          "Withdrawal fee"
  withdrawalAmount:       "Maximum withdrawal amount"
  gte:                    "Field value should be greater or equal to {{value}}"
  lte:                    "Field value should be less or equal to {{value}}"
et:
  title:                  "Deposit details"
  amountInitial:          "Initial amount"
  amountCurrent:          "Current amount"
  period:                 "Deposit period"
  Day:                    "{{ count }} day"
  Day_plural:             "{{ count }} days"
  Week:                   "{{ count }} week"
  Week_plural:            "{{ count }} weeks"
  Month:                  "{{ count }} month"
  Month_plural:           "{{ count }} months"
  Year:                   "{{ count }} year"
  Year_plural:            "{{ count }} years"
  dayBalanceCalc:         "Daily balance calculation"
  startDate:              "Start date"
  endDate:                "End date"
  interestFreq:           "Interest frequency"
  interestRate:           "Interest rate"
  reference:              "Reference"
  payoutBankAccount:      "Payout bank account"
  moneyOrigin:            "Origin of money"
  beneficiary:            "Beneficiary"
  withholdIncomeTax:      "Withhold income tax and rate"
  taxResidency:           "Residency for tax purposes"
  countryPlaceholder:     "Select country"
  autoExtend:             "Extend automatically"
  terminationFee:         "Termination fee"
  withdrawalFee:          "Withdrawal fee"
  withdrawalAmount:       "Maximum withdrawal amount"
  gte:                    "Field value should be greater or equal to {{value}}"
  lte:                    "Field value should be less or equal to {{value}}"
ru:
  title:                  "Deposit details"
  amountInitial:          "Initial amount"
  amountCurrent:          "Current amount"
  period:                 "Deposit period"
  Day_0:                  "{{ count }} день"
  Day_1:                  "{{ count }} дня"
  Day_2:                  "{{ count }} дней"
  Week_0:                 "{{ count }} неделя"
  Week_1:                 "{{ count }} недели"
  Week_2:                 "{{ count }} недель"
  Month_0:                "{{ count }} месяц"
  Month_1:                "{{ count }} месяца"
  Month_2:                "{{ count }} месяцев"
  Year_0:                 "{{ count }} год"
  Year_1:                 "{{ count }} года"
  Year_2:                 "{{ count }} лет"
  dayBalanceCalc:         "Daily balance calculation"
  startDate:              "Start date"
  endDate:                "End date"
  interestFreq:           "Interest frequency"
  interestRate:           "Interest rate"
  reference:              "Reference"
  payoutBankAccount:      "Payout bank account"
  moneyOrigin:            "Origin of money"
  beneficiary:            "Beneficiary"
  withholdIncomeTax:      "Withhold income tax and rate"
  taxResidency:           "Residency for tax purposes"
  countryPlaceholder:     "Select country"
  autoExtend:             "Extend automatically"
  terminationFee:         "Termination fee"
  withdrawalFee:          "Withdrawal fee"
  withdrawalAmount:       "Maximum withdrawal amount"
  gte:                    "Field value should be greater or equal to {{value}}"
  lte:                    "Field value should be less or equal to {{value}}"
</i18n>
