<template lang='pug'>
fi-modal.deposit-terminate-modal(
  ref        = 'modal'
  v-model    = 'showModal'
  :title     = '$t("title")'
  size       = 'lg'
  lazy
  @hide      = 'onHide'
  @shown     = 'resetData'
)
  form.form-horizontal(@submit.prevent = 'onSubmit')
    fi-form-field(
      :label = '$t("date")'
      :state = '!$v.terminateData.date.$error'
    )
      template(#error)
        .error-message(v-if = '!$v.terminateData.date.required') {{ $t('common:required') }}
      fi-datepicker(
        v-model = 'terminateData.date'
        :class  = '{ "is-invalid": $v.terminateData.date.$error }'
        :min-date = 'terminationMinDate'
        :max-date = 'deposit.terms.endDate'
        sm
      )

    fi-form-field(
      v-if   = '!interestReturn'
      :label = '$t("interestPayout")'
    )
      fi-switch(v-model = 'interestPayout')

    fi-form-field.animated.fadeIn(
      v-if   = 'interestPayout || interestReturn'
      :label = '$t(interestAmountTitle)'
      :state = '!$v.terminateData.interestPayoutAmount.$error'
    )
      template(#error)
        .error-message(v-if = '!$v.terminateData.interestPayoutAmount.required') {{ $t('common:required') }}
        .error-message(v-if = '!$v.terminateData.interestPayoutAmount.max') {{ $t('lte', { value: $v.terminateData.interestPayoutAmount.$params.max.max }) }}
      fi-money-input.form-control.form-control-sm(
        v-model = 'terminateData.interestPayoutAmount'
        :class  = '{ "is-invalid": $v.terminateData.interestPayoutAmount.$error }'
      )
    fi-form-field(
      :label    = '$t("terminationFee")'
      :state    = '!$v.terminateData.feeAmount.$error'
      :disabled = '!terminationFeeProduct.feeOverride'
    )
      template(#error)
        .form-row
          .col
          .col
            .error-message(v-if = '!$v.terminateData.feeAmount.required') {{ $t('common:required') }}
            .error-message(v-if = '!$v.terminateData.feeAmount.min') {{ $t('gte', { value: $v.terminateData.feeAmount.$params.min.min }) }}
            .error-message(v-if = '!$v.terminateData.feeAmount.max') {{ $t('lte', { value: $v.terminateData.feeAmount.$params.max.max }) }}
      .form-row
        .col: fi-select(
          v-model   = 'terminateData.feeCalculationType'
          :options  = 'calculationTypes'
          :disabled = '!terminationFeeProduct.feeOverride'
          required
          sm
        )
        .col
          percent-input.form-control.form-control-sm(
            v-if           = 'percentageTerminationFee'
            v-model.number = 'terminateData.feeAmount'
            :class         = '{ "is-invalid": $v.terminateData.feeAmount.$error }'
          )
          fi-money-input.form-control.form-control-sm(
            v-else
            v-model.number = 'terminateData.feeAmount'
            :class         = '{ "is-invalid": $v.terminateData.feeAmount.$error }'
          )
    fi-table(
      :fields = 'fields'
      :items  = 'terminateData.relatedPayments'
    )
      template(#default = '{ items }')
        tr(v-for = '(item, index) in items')
          td {{ item.beneficiaryName }}
          td {{ classifierById('paymentWays', item.paymentWayId).human }}
          td {{ item.beneficiaryExternalAccountNumber }}
          td.money {{ item.amount | money }}
          td
            button.btn.btn-sm.btn-link(@click.prevent = 'editPayout(index)'): i.far.fa-edit.fa-lg
  template(slot = 'modal-footer')
    button.btn.btn-primary(
      :disabled      = 'loader'
      v-fi-confirm   = '{ ok: onSubmit }'
    )
      | {{ $t("common:save") }}
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'loader')
    button.btn.btn-secondary(
      :disabled      = 'loader'
      @click.prevent = '$refs.modal.hide()'
    ) {{ $t("common:cancel") }}
  loan-payout-relation-modal(
    v-if              = 'showPayoutModal'
    v-model           = 'showPayoutModal'
    :payment-relation = 'currentRelation'
    :callback         = 'updatePayouts'
  )
</template>


<script>
import FiDatepicker from '@/components/FiDatepicker'
import FiFormField from '@/components/FiFormField'
import FiMoneyInput from '@/components/FiMoneyInput'
import FiSelect from '@/components/FiSelect'
import FiSwitch from '@/components/FiSwitch'
import PercentInput from '@/components/PercentInput'
import { validation, validators } from '@/validation'
import { mapGetters, mapState } from 'vuex'
import { calculationTypes, convertFee, convertFeeRange, feePercentage } from '@/helpers'
import FiModal from '@/components/FiModal'
import FiTable from '@/components/FiTable'
import LoanPayoutRelationModal from '@/views/loans/LoanPayoutRelationModal'

export default {
  name: 'deposit-terminate-modal',

  components: { LoanPayoutRelationModal, FiTable, FiModal, PercentInput, FiSelect, FiSwitch, FiDatepicker, FiMoneyInput, FiFormField },

  mixins: [validation],

  props: {
    value: {
      type: Boolean,
      required: true
    },
    okAction: {
      type: Function,
      default: null
    },
    loader: {
      type: Boolean,
      required: true
    },
    deposit: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    financial: {
      type: Object,
      required: true
    },
    paymentRelations: {
      type: Array,
      required: true
    },
    feeConfigs: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  data: () => ({
    showPayoutModal: false,
    interestPayout: false,
    terminateData: {
      date: new Date(),
      feeAmount: undefined,
      feeCalculationType: '',
      interestPayoutAmount: undefined,
      relatedPayments: []
    }
  }),

  computed: {
    ...mapGetters('settings', ['settingByKey']),
    ...mapGetters('classifiers', ['optionsFromClassifier', 'classifierById', 'classifierList']),
    ...mapState('loans', ['systemData']),
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    terminationFee () {
      return this.deposit.terms.fees.find(({ feeType }) => feeType.code === 'TERMINATION') || {}
    },
    terminationFeeProduct () {
      const { fees = [] } = this.product
      return fees.find(({ feeType }) => feeType.code === 'TERMINATION') || {}
    },
    convertedTerminationFeeRange () {
      const { currentBalance } = this.deposit
      return convertFeeRange({ ...this.terminationFeeProduct, feeCalculationType: this.terminationFeeCalcType }, currentBalance)
    },
    terminationFeeCalcType () {
      return this.terminateData.feeCalculationType ?? this.terminationFee.feeCalculationType.code
    },
    calculationTypes () {
      return calculationTypes(
        this.terminationFee,
        this.feeConfigs,
        this.classifierList('feeCalculationType')
      )
    },
    percentageTerminationFee () {
      return feePercentage(this.terminationFeeCalcType)
    },
    interestReturn () {
      return /FIXED_DEPOSIT/.test(this.product.productType) && /START_OF_PERIOD/.test(this.deposit.terms.interestPaymentMethod.type.code)
    },
    interestAmountTitle () {
      return this.interestReturn ? 'interestReturnAmount' : 'interestPayoutAmount'
    },
    terminationMinDate () {
      return this.$moment.max(this.$moment(this.systemData.nextDayChange), this.$moment(this.deposit.terms.startDate)).toDate()
    },
    fields () {
      return [
        {
          label: this.$t('beneficiaryName'),
          key: 'beneficiaryName'
        },
        {
          label: this.$t('paymentWay'),
          key: 'paymentWayId'
        },
        {
          label: this.$t('beneficiaryIBAN'),
          key: 'beneficiaryExternalAccountNumber'
        },
        {
          label: this.$t('amount'),
          key: 'amount',
          class: 'money'
        },
        {
          key: 'actions'
        }
      ]
    }
  },

  watch: {
    percentageTerminationFee (val, oldVal) {
      if (typeof oldVal === 'undefined' || val === oldVal) {
        return
      }

      this.terminateData.feeAmount = convertFee({
        feeValue: this.terminateData.feeAmount,
        isPercentage: val,
        amountValue: this.deposit.currentBalance
      })
    },
    interestPayout: {
      handler (value) {
        if (value) {
          this.terminateData.interestPayoutAmount = this.interestReturn ? this.financial.depositInterestPayableBalance : this.deposit.interestAccruedBalance
        } else {
          this.terminateData.interestPayoutAmount = undefined
        }
      },
      immediate: true
    }
  },

  validations () {
    return {
      terminateData: {
        date: {
          required: validators.required
        },
        feeAmount: {
          required: validators.required,
          max: validators.maxValue(this.convertedTerminationFeeRange.feeMax),
          min: validators.minValue(this.convertedTerminationFeeRange.feeMin)
        },
        interestPayoutAmount: {
          required: validators.requiredIf(function () {
            return this.interestPayout
          }),
          max: validators.maxValue(this.interestReturn ? this.financial.depositInterestPayableBalance : this.deposit.interestAccruedBalance)
        }
      }
    }
  },

  methods: {
    resetData () {
      Object.assign(this.$data, this.$options.data.apply(this))
      this.terminateData = {
        ...this.terminateData,
        feeAmount: this.terminationFee.feeAmount,
        feeCalculationType: this.terminationFee.feeCalculationType.code,
        interestPayoutAmount: this.interestReturn ? this.financial.depositInterestPayableBalance : undefined,
        relatedPayments: this.paymentRelations.map(relation => ({ ...relation, amount: this.deposit.currentBalance }))
      }
    },
    onHide (e) {
      if (this.loader) {
        e.preventDefault()
      }
    },
    editPayout (index) {
      this.currentRelation = { ...this.terminateData.relatedPayments[index], id: index + 1 }
      this.showPayoutModal = true
    },
    updatePayouts (payout) {
      this.terminateData.relatedPayments = [payout]
    },
    async onSubmit ({ done }) {
      if (this.validate()) {
        const { relatedPayments } = this.terminateData
        await this.okAction({ ...this.terminateData, relatedPayments: relatedPayments.map(({ id, ...payment }) => payment) })
        done()
        this.$refs.modal && this.$refs.modal.hide()
      }
    }
  }
}
</script>


<i18n>
en:
  title:                "Deposit termination"
  date:                 "Termination date"
  interestPayout:       "Interest accrued payout"
  interestPayoutAmount: "Interest payout amount"
  interestReturnAmount: "Interest return"
  terminationFee:       "Termination fee"
  gte:                  "Field value should be greater or equal to {{value}}"
  lte:                  "Field value should be less or equal to {{value}}"
  payoutAccount:        "Payout bank account"
  paymentWay:           "Payment way"
  payableAfterTime:     "Payable after time"
  beneficiaryName:      "Beneficiary name"
  beneficiaryIBAN:      "Beneficiary IBAN"
  amount:               "Amount"
  payoutRelations:      "Payouts"
  amountError:          "Total payout amount should be {{ amount }}"
et:
  title:                "Deposit termination"
  date:                 "Termination date"
  interestPayout:       "Interest accrued payout"
  interestPayoutAmount: "Interest payout amount"
  interestReturnAmount: "Interest return"
  terminationFee:       "Termination fee"
  gte:                  "Field value should be greater or equal to {{value}}"
  lte:                  "Field value should be less or equal to {{value}}"
  payoutAccount:        "Payout bank account"
  paymentWay:           "Payment way"
  payableAfterTime:     "Payable after time"
  beneficiaryName:      "Beneficiary name"
  beneficiaryIBAN:      "Beneficiary IBAN"
  amount:               "Amount"
  payoutRelations:      "Payouts"
  amountError:          "Total payout amount should be {{ amount }}"
ru:
  title:                "Deposit termination"
  date:                 "Termination date"
  interestPayout:       "Interest accrued payout"
  interestPayoutAmount: "Interest payout amount"
  interestReturnAmount: "Interest return"
  terminationFee:       "Termination fee"
  gte:                  "Field value should be greater or equal to {{value}}"
  lte:                  "Field value should be less or equal to {{value}}"
  payoutAccount:        "Payout bank account"
  paymentWay:           "Payment way"
  payableAfterTime:     "Payable after time"
  beneficiaryName:      "Beneficiary name"
  beneficiaryIBAN:      "Beneficiary IBAN"
  amount:               "Amount"
  payoutRelations:      "Payouts"
  amountError:          "Total payout amount should be {{ amount }}"
</i18n>
