<template lang='pug'>
.deposit-new.animated.fadeIn
  form.form-horizontal(
    novalidate
    @submit.prevent = 'onSubmit'
  )
    .card.mb-0.border-bottom-0
      .card-header {{ $t('title') }}
      .card-body
        .row
          .col-lg-6
            fi-form-field(
              :label = '$t("product")'
              :state = '!$v.termsData.productId.$error'
            )
              template(#label)
                i.fa.fa-spinner.fa-pulse.fa-pull-right(v-if = '$vueLoading.isLoading("products:fetch")')

              template(#error)
                .error-message(v-if = '!$v.termsData.productId.required') {{ $t('common:required') }}

              fi-select(
                v-model.number = '$v.termsData.productId.$model'
                :options       = 'optionsFromProducts'
                :disabled      = '$vueLoading.isLoading("products:fetch")'
                :placeholder   = '$t("productPlaceholder")'
                :class         = '{ "is-invalid": $v.termsData.productId.$error }'
                required
                sm
              )

    .card.mb-0.border-bottom-0
      .card-header {{ $t('customerData') }}
      .card-body
        .row
          .col-lg-6
            fi-form-field(:label = '$t("existingCustomer")')
              fi-customer-select(
                v-model      = 'selectedCustomerModel'
                :sector-id   = 'depositProduct.sectorId'
                :placeholder = '$t("existingCustomerPh")'
              )
        v-loading(loader = 'systemCustomers:fetch')
          template(#spinner)
            .text-center: i.fa.fa-spinner.fa-pulse.fa-lg
          .row
            .col-lg-6.animated.fadeIn
              fi-form-field(
                v-if      = 'isCorporateCustomer(selectedCustomerModel)'
                :label    = '$t("customerName")'
                :state    = '!$v.customerData.customerName.$error'
                :disabled = '!Boolean(customerData.id)'
              )
                template(#error)
                  .error-message(v-if = '!$v.customerData.customerName.required') {{ $t('common:required') }}

                input.form-control.form-control-sm(
                  type         = 'text'
                  v-model.trim = '$v.customerData.customerName.$model'
                  :class       = '{ "is-invalid": $v.customerData.customerName.$error }'
                )

              template(v-else)
                fi-form-field(
                  :label    = '$t("firstName")'
                  :state    = '!$v.customerData.firstName.$error'
                  :disabled = '!Boolean(customerData.id)'
                )
                  template(#error)
                    .error-message(v-if = '!$v.customerData.firstName.required') {{ $t('common:required') }}

                  input.form-control.form-control-sm(
                    type         = 'text'
                    v-model.trim = '$v.customerData.firstName.$model'
                    :class       = '{ "is-invalid": $v.customerData.firstName.$error }'
                  )

                fi-form-field(
                  :label    = '$t("lastName")'
                  :state    = '!$v.customerData.lastName.$error'
                  :disabled = '!Boolean(customerData.id)'
                )
                  template(#error)
                    .error-message(v-if = '!$v.customerData.lastName.required') {{ $t('common:required') }}

                  input.form-control.form-control-sm(
                    type         = 'text'
                    v-model.trim = '$v.customerData.lastName.$model'
                    :class       = '{ "is-invalid": $v.customerData.lastName.$error }'
                  )

              fi-form-field(
                :label    = '$t("idCode")'
                :state    = '!$v.customerData.idCode.$error'
                :disabled = '!Boolean(customerData.id)'
              )
                template(#error)
                  .error-message(v-if = '!$v.customerData.idCode.required') {{ $t('common:required') }}

                input.form-control.form-control-sm(
                  type         = 'text'
                  v-model.trim = '$v.customerData.idCode.$model'
                  :class       = '{ "is-invalid": $v.customerData.idCode.$error }'
                )

              template(v-if = '!isCorporateCustomer(selectedCustomerModel)')
                fi-form-field(
                  :label    = '$t("documentType")'
                  :state    = '!$v.documentData.documentType.$error'
                  :disabled = '!Boolean(customerData.id)'
                )
                  template(#error)
                    .error-message(v-if = '!$v.documentData.documentType.required') {{ $t('common:required') }}

                  fi-select(
                    v-model.number = '$v.documentData.documentType.$model'
                    :options       = 'optionsFromClassifier("idDocs", true)'
                    :class         = '{ "is-invalid": $v.documentData.documentType.$error }'
                    :placeholder   = '$t("idDocPlaceholder")'
                    required
                    sm
                  )

                fi-form-field(
                  :label    = '$t("documentNumber")'
                  :state    = '!$v.documentData.documentNumber.$error'
                  :disabled = '!Boolean(customerData.id)'
                )
                  template(#error)
                    .error-message(v-if = '!$v.documentData.documentNumber.required') {{ $t('common:required') }}

                  input.form-control.form-control-sm(
                    v-model.number = '$v.documentData.documentNumber.$model'
                    type           = 'text'
                    :class         = '{ "is-invalid": $v.documentData.documentNumber.$error }'
                  )

                  .alert.alert-danger(v-if = 'isIdDocExpire === true') {{ $t('expireDate', { idDocExpire: $t('common:formatDate', { value: idDocExpireDate }) }) }}
                  .alert.alert-success(v-else-if = 'isIdDocExpire === false') {{ $t('expireDate', { idDocExpire: $t('common:formatDate', { value: idDocExpireDate }) }) }}

              fi-form-field(
                :label    = '$t("phone")'
                :state    = '!$v.customerData.phoneNumber.$error'
                :disabled = '!Boolean(customerData.id)'
              )
                template(#error)
                  .error-message(v-if = '!$v.customerData.phoneNumber.required') {{ $t('common:required') }}
                fi-phone-input.form-control.form-control-sm(
                  v-model.trim = '$v.customerData.phoneNumber.$model'
                  :countries   = 'countries'
                  :class       = '{ "is-invalid": $v.customerData.phoneNumber.$error }'
                  :disabled    = '!Boolean(customerData.id)'
                )
              fi-form-field(
                :label    = '$t("deskPhone")'
              )
                fi-phone-input.form-control.form-control-sm(
                  v-model.trim = '$v.customerData.deskPhoneNumber.$model'
                  :countries   = 'countries'
                  :disabled    = '!Boolean(customerData.id)'
                )
              fi-form-field(
                :label    = '$t("email")'
                :state    = '!$v.customerData.email.$error'
                :disabled = '!Boolean(customerData.id)'
              )
                template(#error)
                  .error-message(v-if = '!$v.customerData.email.required') {{ $t('common:required') }}
                  .error-message(v-if = '!$v.customerData.email.isEmail') {{ $t('emailErr') }}
                input.form-control.form-control-sm(
                  v-model.number = '$v.customerData.email.$model'
                  type           = 'text'
                  :class         = '{ "is-invalid": $v.customerData.email.$error }'
                )
            .col-lg-6
              fi-form-field(
                :label    = '$t("country")'
                :state    = '!$v.addressData.countryId.$error'
                :disabled = '!Boolean(customerData.id)'
              )
                template(#error)
                  .error-message(v-if = '!$v.addressData.countryId.required') {{ $t('common:required') }}
                fi-select(
                  v-model.number = '$v.addressData.countryId.$model'
                  :options       = 'optionsFromClassifier("countries")'
                  :class         = '{ "is-invalid": $v.addressData.countryId.$error }'
                  :placeholder   = '$t("countryPlaceholder")'
                  required
                  sm
                )
              fi-form-field(
                :label    = '$t("county")'
                :state    = '!$v.addressData.countyId.$error'
                :disabled = '!Boolean(customerData.id)'
              )
                template(#error)
                  .error-message(v-if = '!$v.addressData.countyId.required') {{ $t('common:required') }}

                fi-select(
                  v-model.number = '$v.addressData.countyId.$model'
                  :options       = 'countyOptions'
                  :class         = '{ "is-invalid": $v.addressData.countyId.$error }'
                  :placeholder   = '$t("countyPlaceholder")'
                  required
                  sm
                )

              fi-form-field(
                :label    = '$t("city")'
                :state    = '!$v.addressData.city.$error'
                :disabled = '!Boolean(customerData.id)'
              )
                template(#error)
                  .error-message(v-if = '!$v.addressData.city.required') {{ $t('common:required') }}

                input.form-control.form-control-sm(
                  v-model.number = '$v.addressData.city.$model'
                  type           = 'text'
                  :class         = '{ "is-invalid": $v.addressData.city.$error }'
                )

              fi-form-field(
                :label    = '$t("address")'
                :state    = '!$v.addressData.address.$error'
                :disabled = '!Boolean(customerData.id)'
              )
                template(#error)
                  .error-message(v-if = '!$v.addressData.address.required') {{ $t('common:required') }}

                input.form-control.form-control-sm(
                  v-model.number = '$v.addressData.address.$model'
                  type           = 'text'
                  :class         = '{ "is-invalid": $v.addressData.address.$error }'
                )

              fi-form-field(
                :label    = '$t("postalCode")'
                :state    = '!$v.addressData.postalCode.$error'
                :disabled = '!Boolean(customerData.id)'
              )
                template(#error)
                  .error-message(v-if = '!$v.addressData.postalCode.required') {{ $t('common:required') }}

                input.form-control.form-control-sm(
                  v-model.number = '$v.addressData.postalCode.$model'
                  type           = 'text'
                  :class         = '{ "is-invalid": $v.addressData.postalCode.$error }'
                )

              fi-form-field(
                :label = '$t("taxResidency")'
                :state = '!$v.termsData.withholdTax.residenceCountryId.$error'
              )
                template(#error)
                  .error-message(v-if = '!$v.termsData.withholdTax.residenceCountryId.required') {{ $t('common:required') }}

                fi-select(
                  v-model.number = '$v.termsData.withholdTax.residenceCountryId.$model'
                  :options       = 'optionsFromClassifierTaxCountry()'
                  :class         = '{ "is-invalid": $v.termsData.withholdTax.residenceCountryId.$error }'
                  :placeholder   = '$t("countryPlaceholder")'
                  sm
                )

              fi-form-field.mt-1(:label = '$t("attachments")')
                .row.mb-3
                  .col
                    .input-group.input-group-sm
                      .input-group-prepend
                        span.input-group-text
                          i.fa.fa-file-alt
                      fi-select(
                        v-model.number = 'documentCategoryId'
                        :options       = 'optionsFromClassifier("DocumentCategoryType")'
                        :placeholder   = '$t("category")'
                        sm
                      )
                .row
                  .col
                    fi-file-input.form-control-sm(
                      v-model = 'attachments'
                      multiple
                    )
    .card.mb-0.border-bottom-0
      .card-header {{ $t('depositData') }}
      .card-body
        .row
          .col-lg-6
            fi-form-field(
              :label = '$t("amount")'
              :state = '!$v.termsData.amount.$error'
            )
              template(#error)
                .error-message(v-if = '!$v.termsData.amount.required') {{ $t('common:required') }}

              fi-money-input.form-control.form-control-sm(
                v-model.number = '$v.termsData.amount.$model'
                type           = 'text'
                :class         = '{ "is-invalid": $v.termsData.amount.$error }'
              )
            fi-form-field(
              :label = '$t("period")'
              :state = '!$v.termsData.periodQuantity.$error'
            )
              template(#error)
                .error-message(v-if = '!$v.termsData.periodQuantity.required') {{ $t('common:required') }}

              .row
                .col
                  input.form-control.form-control-sm(
                    v-model.number = '$v.termsData.periodQuantity.$model'
                    type           = 'text'
                    :class         = '{ "is-invalid": $v.termsData.periodQuantity.$error }'
                  )
                .col
                  input.form-control.form-control-sm(
                    :value = 'productPeriodUnitType'
                    readonly
                    disabled
                  )

            fi-form-field(
              :label = '$t("startDate")'
              :state = '!$v.termsData.startDate.$error'
            )
              template(#error)
                .error-message(v-if = '!$v.termsData.startDate.required') {{ $t('common:required') }}

              fi-datepicker(
                v-model   = '$v.termsData.startDate.$model'
                :class    = '{ "is-invalid": $v.termsData.startDate.$error }'
                :min-date = 'systemData.nextDayChange'
              )
            fi-form-field(
              :label = '$t("endDate")'
              disabled
            )
              fi-datepicker(:value = 'endDate')

            fi-form-field(
              :label = '$t("interestPeriod")'
              :state = '!$v.termsData.interestPaymentMethod.$error'
            )
              template(#error)
                .error-message(v-if = '!$v.termsData.interestPaymentMethod.required') {{ $t('common:required') }}

              fi-select(
                v-model      = '$v.termsData.interestPaymentMethod.$model'
                :options     = 'paymentMethods'
                :class       = '{ "is-invalid": $v.termsData.interestPaymentMethod.$error }'
                :placeholder = '$t("interestPeriodPh")'
                required
                sm
              )

            fi-form-field(:label = '$t("interestRate")')
              percent-input.form-control.form-control-sm(
                :value = 'interestRate'
                readonly
                disabled
              )

            deposit-details-fee-item(
              v-if        = 'termsData.fee'
              :fee        = '$v.termsData.fee'
              :amount     = 'termsData.amount || 0'
              :fee-configs = 'feeConfigs'
              editing
            )

            fi-form-field(
              :label = '$t("reference")'
              :state = '!$v.depositData.paymentReferenceId.$error'
            )
              template(#error)
                .error-message(v-if = '!$v.depositData.paymentReferenceId.required') {{ $t('common:required') }}

              fi-select(
                v-model.number = '$v.depositData.paymentReferenceId.$model'
                :options       = 'optionsFromCustomerAccounts'
                :placeholder   = '$t("referencePh")'
                :class         = '{ "is-invalid": $v.depositData.paymentReferenceId.$error }'
                sm
              )
          .col-lg-6
            fi-form-field(
              :label = '$t("moneyOrigin")'
              :state = '!$v.termsData.originOfMoney.$error'
            )
              template(#error)
                .error-message(v-if = '!$v.termsData.originOfMoney.required') {{ $t('common:required') }}
              fi-multiselect(
                v-model       = '$v.termsData.originOfMoney.$model'
                :options      = 'optionsFromClassifier("incomeSources")'
                :class        = '{ "is-invalid": $v.termsData.originOfMoney.$error }'
                multiple
                hide-selected
              )

            fi-form-field(
              :label = '$t("beneficiary")'
              :state = '!$v.termsData.beneficiary.$error'
            )
              template(#error)
                .error-message(v-if = '!$v.termsData.beneficiary.required') {{ $t('common:required') }}

              input.form-control.form-control-sm(
                v-model.number = '$v.termsData.beneficiary.$model'
                type           = 'text'
                :class         = '{ "is-invalid": $v.termsData.beneficiary.$error }'
              )

            fi-form-field(
              :label = '$t("withholdTax")'
              :state = '!$v.termsData.withholdTax.$error'
            )
              .form-row
                .col
                  fi-switch(v-model   = 'termsData.withholdTax.enabled')
                .col.animated.fadeIn(v-if = 'termsData.withholdTax.enabled')
                  fi-form-field(:state = '!$v.termsData.withholdTax.rate.$error')
                    fi-select(
                      v-model = '$v.termsData.withholdTax.rate.$model'
                      :options       = 'optionsFromClassifierTax($v.termsData.withholdTax.residenceCountryId.$model)'
                      sm
                    )
                    template(#error)
                      .error-message(v-if = '!$v.termsData.withholdTax.rate.required') {{ $t('common:required') }}

            fi-form-field(:label = '$t("autoExtend")')
              fi-switch(v-model   = 'termsData.autoExtendableEnabled')

            fi-form-field(
              v-if      = 'isCompoundInterestPossible'
              :label    = '$t("compoundInterest")'
              :disabled = 'isCompoundInterestDisabled'
            )
              fi-switch(v-model = '$v.termsData.compoundInterest.$model')

            fi-form-field(
              :label = '$t("interestAccount")'
              :state = '!$v.termsData.interestPaymentAccount.$error'
            )
              template(#error)
                .error-message(v-if = '!$v.termsData.interestPaymentAccount.required') {{ $t('common:required') }}

              fi-select(
                v-model.number = '$v.termsData.interestPaymentAccount.$model'
                :options       = 'optionsFromClassifier("interestPaymentAccountType")'
                :placeholder   = '$t("interestAccountsPh")'
                :class         = '{ "is-invalid": $v.termsData.interestPaymentAccount.$error }'
                required
                sm
              )

            fi-form-field(
              :label = '$t("payoutBankAccount")'
              :state = '!$v.termsData.payoutBankAccount.$error'
            )
              template(#error)
                .error-message(v-if = '!$v.termsData.payoutBankAccount.required') {{ $t('common:required') }}

              fi-select(
                v-model.trim = '$v.termsData.payoutBankAccount.$model'
                :options     = 'bankAccountOptions'
                sm
                required
              )
              input.form-control.form-control-sm(
                v-model.number = '$v.termsData.payoutBankAccount.$model'
                type           = 'text'
                :class         = '{ "is-invalid": $v.termsData.payoutBankAccount.$error }'
              )
    .card
      .card-body
        button.btn.btn-primary(
          type      = 'submit'
          :disabled = 'saving'
        )
          |{{ $t("common:save") }}
          i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
        | &nbsp;
        button.btn.btn-secondary(
          :disabled      = 'saving'
          @click.prevent = 'onCancel'
        ) {{ $t("common:cancel") }}
</template>

<script>
import FiDatepicker from '@/components/FiDatepicker'
import FiFileInput from '@/components/FiFileInput'
import FiFormField from '@/components/FiFormField'
import FiMoneyInput from '@/components/FiMoneyInput'
import FiMultiselect from '@/components/FiMultiselect'
import FiSelect from '@/components/FiSelect'
import FiSwitch from '@/components/FiSwitch'
import PercentInput from '@/components/PercentInput'
import { ContractDeposit } from '@/models'

import { validation } from '@/validation'
import { mapActions, mapGetters, mapState } from 'vuex'
import pick from 'lodash/pick'
import { convertFee, convertFeeRange, feePercentage, findCustomerMainContact } from '@/helpers'
import DepositDetailsFeeItem from '@/components/FiFeeItem'
import FiPhoneInput from '@/components/FiPhoneInput'
import FiCustomerSelect from '@/components/FiCustomerSelect'
// import { newContractTermsFields } from '@/models/contracts/ContractDeposit'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'deposit-new',

  components: {
    FiCustomerSelect,
    FiPhoneInput,
    DepositDetailsFeeItem,
    FiSwitch,
    PercentInput,
    FiDatepicker,
    FiMoneyInput,
    FiFileInput,
    FiMultiselect,
    FiFormField,
    FiSelect
  },

  mixins: [validation],

  props: {
    customer: {
      type: Object,
      default: null
    }
  },

  i18nOptions: {},

  data: () => {
    const deposit = ContractDeposit().newContractModel
    return {
      selectedCustomer: null,
      attachments: [],
      documentCategoryId: '',
      termsData: deposit.termsData,
      depositData: deposit.depositData,
      customerData: deposit.customerData,
      addressData: deposit.addressData,
      documentData: deposit.documentData
    }
  },

  computed: {
    ...mapState('products', ['feeConfigs']),
    ...mapState('classifiers', ['classifiers']),
    ...mapGetters('classifiers', [
      'classifierByName',
      'optionsFromClassifier',
      'optionsFromCreditIssuers',
      'optionsFromCountiesByCountry',
      'classifierList',
      'classifierById'
    ]),
    ...mapGetters('products', ['optionsFromProducts']),
    ...mapGetters('deposits', ['optionsFromCustomerAccounts', 'depositProductFees']),
    ...mapState('deposits', ['depositProduct', 'depositContract']),
    ...mapState('loans', ['systemData']),
    ...mapGetters('settings', ['settingByKey']),
    ...mapGetters('customers', ['customerName', 'isCorporateCustomer']),
    selectedCustomerModel: {
      get () {
        return this.selectedCustomer
      },
      set (selected) {
        this.selectedCustomer = selected
        this.customerData.id = selected?.id ?? null
        if (selected) {
          this.linkSystemCustomer(selected)
        }
      }
    },
    countyOptions () {
      return this.optionsFromCountiesByCountry(this.addressData.countryId)
    },
    endDate () {
      const { periodQuantity = 0, startDate } = this.termsData
      return this.$moment(startDate).add(periodQuantity, this.productPeriodUnitType).toDate()
    },
    productPeriodUnitType () {
      return this.classifierByName('periodUnitType', this.depositProduct.periodUnitType)?.human
    },
    interestRate () {
      const { amount, periodQuantity } = this.termsData
      const targetAmountRange = this.depositProduct?.amountRangePeriodInterests?.find(({ amountRange }) => amount >= amountRange.from && amount <= amountRange.to)
      return targetAmountRange?.periodRangeInterests?.find(({ periodRange }) => periodQuantity >= periodRange.from && periodQuantity <= periodRange.to)?.interest
    },
    saving () {
      return this.$vueLoading.isLoading('deposits:contract:save')
    },
    customerAddress () {
      return this.selectedCustomerModel?.addresses?.find(({ isDefault }) => isDefault) || {}
    },
    idDocExpireDate () {
      return this.selectedCustomerModel?.privateCustomer?.idDocExpireDate
    },
    isIdDocExpire () {
      if (!this.idDocExpireDate) return ''

      return this.$moment(this.idDocExpireDate).isBefore()
    },
    deposit () {
      return ContractDeposit({
        ...this.depositData,
        customer: {
          ...this.customerData,
          address: {
            ...this.addressData
          },
          document: {
            ...this.documentData
          }
        },
        terms: {
          ...this.termsData
        }
      })
    },
    paymentMethods () {
      const productPaymentMethodsIds = this.depositProduct?.interestPaymentMethods?.map(({ type }) => type.code)
      return this.optionsFromClassifier('interestPaymentMethod', true).filter(({ value }) => productPaymentMethodsIds?.includes(value))
    },
    isCompoundInterestPossible () {
      return this.depositProduct.compoundInterest
    },
    isCompoundInterestDisabled () {
      const { interestPaymentMethod } = this.termsData
      const { autoExtendableEnabled } = this.termsData
      return !autoExtendableEnabled && (interestPaymentMethod === 'START_OF_PERIOD' || interestPaymentMethod === 'END_OF_PERIOD')
    },
    agreementFee: {
      get () {
        if (!this.agreementFeeProduct) return null

        return {
          ...this.agreementFeeProduct,
          ...this.termsData.fee
        }
      },
      set (agreementFee) {
        this.termsData.fee = agreementFee
      }
    },
    agreementFeeProduct () {
      return this.depositProductFees.AGREEMENT
    },
    percentageAgreementFee () {
      return feePercentage(this.agreementFee?.feeCalculationType?.code)
    },
    convertedAgreementFeeRange () {
      const { amount } = this.termsData
      return convertFeeRange(this.agreementFee, amount)
    },
    countries () {
      return Object.values(this.classifierList('countries')).map(({ name }) => name)
    },
    bankAccountOptions () {
      const { externalBankAccounts = [], accounts = [] } = this.selectedCustomerModel ?? {}

      if (!this.termsData.interestPaymentAccount) return []

      return (this.isExternalAccount
        ? externalBankAccounts.map(({ accountExternalNumber }) => accountExternalNumber)
        : accounts.filter(({ accountTypeId }) => /prepayment/i.test(this.classifierById('accountTypes', accountTypeId).code))
          .map(({ number }) => number)) ?? []
    },
    isExternalAccount () {
      return /PAYOUT_BANK_ACCOUNT/i.test(this.classifierById('interestPaymentAccountType', this.termsData.interestPaymentAccount).code)
    }
  },

  watch: {
    selectedCustomer (value) {
      if (!this.isCorporateCustomer(value)) {
        this.$v.termsData.beneficiary.$model = value.customerName || `${value.firstName} ${value.lastName}`
      } else {
        this.$v.termsData.beneficiary.$model = ''
      }
    },
    async 'termsData.productId' (value) {
      if (value) {
        await this.loadDepositProduct({ id: value })
        this.processProduct()
      }
      this.$v.$reset()
    },
    'agreementFee.feeCalculationType.code' (val, oldVal) {
      const { feeAmount } = this.agreementFee
      if (val === oldVal || !oldVal) return
      const amount = convertFee({
        feeValue: feeAmount,
        isPercentage: this.percentageAgreementFee,
        amountValue: this.termsData.amount
      })

      this.agreementFee = {
        ...this.agreementFee,
        feeAmount: amount
      }
    },
    'termsData.interestPaymentAccount' (value) {
      if (!value) return
      this.$v.termsData.payoutBankAccount.$model = this.bankAccountOptions[0]
    },
    async 'termsData.withholdTax.enabled' (value) {
      if (this.$v.termsData.withholdTax.residenceCountryId.$model) {
        if (value) {
          await this.classifiers.TAXATION_COUNTRIES.forEach((current) => {
            if (current.countryDto.id === this.$v.termsData.withholdTax.residenceCountryId.$model && current.default) {
              this.$v.termsData.withholdTax.rate.$model = current.rate
            }
          })
        }
      }
    },
    async '$v.termsData.withholdTax.residenceCountryId.$model' (value) {
      if (this.termsData.withholdTax.enabled) {
        if (value) {
          await this.classifiers.TAXATION_COUNTRIES.forEach((current) => {
            if (current.countryDto.id === value && current.default) {
              this.$v.termsData.withholdTax.rate.$model = current.rate
            }
          })
        }
      }
    }
  },

  validations () {
    const { addressData, customerData, depositData, documentData, termsData } = this.deposit.newContractModel.validations
    return {
      depositData,
      customerData,
      documentData,
      addressData,
      termsData: {
        ...termsData,
        fee: {
          feeType: {
            classifier: {},
            code: {}
          },
          feeAmount: {},
          feeCalculationType: {
            classifier: {},
            code: {}
          }
        }
      }
    }
  },

  async created () {
    await this.loadProducts({ group: 'DEPOSIT' })
    await this.loadFeeConfig({ group: 'DEPOSIT' })
    await this.loadTaxationCountries()
    this.selectedCustomerModel = this.customer
  },

  methods: {
    ...mapActions('products', ['loadProducts', 'loadFeeConfig']),
    ...mapActions('deposits', ['loadDepositCustomerAccounts', 'loadDepositProduct', 'createDepositContract']),
    ...mapActions('classifiers', ['loadTaxationCountries']),
    optionsFromClassifierTaxCountry () {
      const options = []
      this.optionsFromClassifier('taxationCountries').forEach((current) => {
        if (!options.some(option => option.value === current.value.id)) {
          options.push({ text: current.value.name, value: current.value.id })
        }
      })
      return options
    },
    optionsFromClassifierTax (id) {
      const options = []
      this.optionsFromClassifier('taxationCountries').forEach((current) => {
        if (current.value.id === id) {
          options.push({ text: `${(current.text) * 100} %`, value: current.text })
        }
      })
      return options
    },
    linkSystemCustomer (customer) {
      const { privateCustomer: { idDocId, idDocNumber } = {} } = customer ?? {}
      this.loadDepositCustomerAccounts({ customerId: customer.id })
      this.customerData = {
        ...this.customerData,
        ...pick(customer, Object.keys(this.customerData)),
        email: findCustomerMainContact(this.selectedCustomerModel?.emails),
        phoneNumber: findCustomerMainContact(customer?.phoneNumbers),
        deskPhoneNumber: findCustomerMainContact(customer?.deskPhoneNumbers)
      }
      this.addressData = {
        ...this.addressData,
        ...pick(this.customerAddress, Object.keys(this.addressData)),
        address: this.customerAddress.fullAddress
      }
      // this.termsData = {
      //   ...this.termsData,
      //   ...pick(this.depositProduct, newContractTermsFields)
      // }
      this.termsData.interestPaymentAccount = this.classifierByName('interestPaymentAccountType', 'PAYOUT_BANK_ACCOUNT')?.id
      this.termsData.withholdTax.residenceCountryId = customer.countryId
      this.termsData.payoutBankAccount = this.bankAccountOptions
      this.documentData = {
        documentNumber: idDocNumber,
        documentType: this.classifierById('idDocs', idDocId).code
      }
      this.$v.$reset()
    },
    processProduct () {
      this.termsData = this.deposit.newContractModel.termsData
      this.depositData.creditIssuerId = this.depositProduct.creditIssuerId
      this.termsData.originOfMoneyLimit = this.depositProduct.originOfMoneyLimit
      this.termsData.amount = this.depositProduct.amountRangePeriodInterests?.[0].amountRange?.from
      if (this.agreementFeeProduct) {
        this.agreementFee = cloneDeep(this.agreementFeeProduct)
      }
    },
    async onSubmit () {
      this.deposit.dump().customer.address.fullAddress = this.deposit.dump().customer.address.address
      delete this.deposit.dump().customer.address.address
      if (this.validate()) {
        await this.createDepositContract({
          contractData: this.deposit.dump(),
          files: this.attachments,
          documentCategoryId: this.documentCategoryId
        })
        this.$router.push({ name: 'Deposit', params: { id: this.depositContract.id } })
      }
    },
    onCancel () {
      this.$router.push({ name: 'Deposits' })
    }
  }
}
</script>


<i18n>
en:
  title:              "Create deposit contract"
  product:            "Product"
  productPlaceholder: "Select product"
  creditIssuer:       "Credit issuer"
  creditIssuerPh:     "Select credit issuer"
  customerData:       "Customer data"
  existingCustomer:   "Existing customer"
  existingCustomerPh: "Select existing customer"
  firstName:          "First name"
  lastName:           "Last name"
  customerName:       "Name"
  idCode:             "ID code"
  documentType:       "Document type"
  idDocPlaceholder:   "Select document type"
  documentNumber:     "Document number"
  phone:              "Mobile phone"
  deskPhone:          "Desk phone"
  email:              "Email"
  country:            "Country"
  countryPlaceholder: "Select country"
  county:             "County"
  countyPlaceholder:  "Select county"
  city:               "City"
  address:            "Address"
  postalCode:         "Postal code"
  taxResidency:       "Residency for tax purposes"
  attachments:        "Attachments"
  category:           "Category"
  depositData:        "Deposit data"
  amount:             "Amount"
  period:             "Period"
  startDate:          "Start date"
  endDate:            "End date"
  interestPeriod:     "Interest frequency"
  interestPeriodPh:   "Select interest frequency"
  interestRate:       "Interest rate"
  reference:          "Payment reference"
  referencePh:        "Select payment reference"
  moneyOrigin:        "The origin of money"
  beneficiary:        "Beneficiary"
  withholdTax:        "Withhold income tax and rate"
  autoExtend:         "Extend automatically"
  interestAccount:    "Payout account for principal and interest"
  interestAccountsPh: "Select interest payment account"
  payoutBankAccount:  "Payout bank account number"
  compoundInterest:   "Compound interest"
  expireDate:         "Expire date: {{ idDocExpire }}"
et:
  title:              "Create deposit contract"
  product:            "Product"
  productPlaceholder: "Select product"
  creditIssuer:       "Credit issuer"
  creditIssuerPh:     "Select credit issuer"
  customerData:       "Customer data"
  existingCustomer:   "Existing customer"
  existingCustomerPh: "Select existing customer"
  firstName:          "First name"
  lastName:           "Last name"
  customerName:       "Name"
  idCode:             "ID code"
  documentType:       "Document type"
  idDocPlaceholder:   "Select document type"
  documentNumber:     "Document number"
  phone:              "Mobile phone"
  deskPhone:          "Desk phone"
  email:              "Email"
  country:            "Country"
  countryPlaceholder: "Select country"
  county:             "County"
  countyPlaceholder:  "Select county"
  city:               "City"
  address:            "Address"
  postalCode:         "Postal code"
  taxResidency:       "Residency for tax purposes"
  attachments:        "Attachments"
  category:           "Category"
  depositData:        "Deposit data"
  amount:             "Amount"
  period:             "Period"
  startDate:          "Start date"
  endDate:            "End date"
  interestPeriod:     "Interest frequency"
  interestPeriodPh:   "Select interest frequency"
  interestRate:       "Interest rate"
  reference:          "Payment reference"
  referencePh:        "Select payment reference"
  moneyOrigin:        "The origin of money"
  beneficiary:        "Beneficiary"
  withholdTax:        "Withhold income tax and rate"
  autoExtend:         "Extend automatically"
  interestAccount:    "Payout account for principal and interest"
  interestAccountsPh: "Select interest payment account"
  payoutBankAccount:  "Payout bank account number"
  compoundInterest:   "Compound interest"
  expireDate:         "Expire date: {{ idDocExpire }}"
ru:
  title:              "Create deposit contract"
  product:            "Product"
  productPlaceholder: "Select product"
  creditIssuer:       "Credit issuer"
  creditIssuerPh:     "Select credit issuer"
  customerData:       "Customer data"
  existingCustomer:   "Existing customer"
  existingCustomerPh: "Select existing customer"
  firstName:          "First name"
  lastName:           "Last name"
  customerName:       "Name"
  idCode:             "ID code"
  documentType:       "Document type"
  idDocPlaceholder:   "Select document type"
  documentNumber:     "Document number"
  phone:              "Mobile phone"
  deskPhone:          "Desk phone"
  email:              "Email"
  country:            "Country"
  countryPlaceholder: "Select country"
  county:             "County"
  countyPlaceholder:  "Select county"
  city:               "City"
  address:            "Address"
  postalCode:         "Postal code"
  taxResidency:       "Residency for tax purposes"
  attachments:        "Attachments"
  category:           "Category"
  depositData:        "Deposit data"
  amount:             "Amount"
  period:             "Period"
  startDate:          "Start date"
  endDate:            "End date"
  interestPeriod:     "Interest frequency"
  interestPeriodPh:   "Select interest frequency"
  interestRate:       "Interest rate"
  reference:          "Payment reference"
  referencePh:        "Select payment reference"
  moneyOrigin:        "The origin of money"
  beneficiary:        "Beneficiary"
  withholdTax:        "Withhold income tax and rate"
  autoExtend:         "Extend automatically"
  interestAccount:    "Payout account for principal and interest"
  interestAccountsPh: "Select interest payment account"
  payoutBankAccount:  "Payout bank account number"
  compoundInterest:   "Compound interest"
  expireDate:         "Expire date: {{ idDocExpire }}"
</i18n>
