<template lang='pug'>
.deposit-revision-list.card
  .card-header {{ $t('title') }}
  .card-body
    fi-table(
      :fields = 'fields'
      :items  = 'revisions'
      responsive
    )
      template(v-slot = '{ items }')
        deposit-revision-list-item(
          v-for     = 'revision in items'
          :value    = '$v.reasonCommentModel'
          :key      = 'revision.id'
          :revision = 'revision'
          :active   = 'revision.id === selectedRevision.id'
          @input    = '$v.reasonCommentModel.$model = $event'
          @select   = '$listeners.select'
          @reason   = 'saveReason'
        )
    template(v-if = 'editable')
      template(v-if = 'selectedRevisionStatus === "ACTIVE"')
        button.animated.fadeIn.btn.btn-primary.mx-1(@click = 'createRevision') {{ $t('change') }}
          i.fa.fa-spinner.fa-pulse.ml-1(v-if = '$vueLoading.isLoading("deposits:terms:revision:save")')
      template(v-if = 'selectedRevisionStatus === "PENDING"')
        button.animated.fadeIn.btn.btn-success.mx-1(@click = 'activateRevision') {{ $t('activate') }}
          i.fa.fa-spinner.fa-pulse.ml-1(v-if = '$vueLoading.isLoading(`deposits:terms:revision:${selectedRevision.id}:accept:save`)')
        button.animated.fadeIn.btn.btn-danger.mx-1(@click = 'removeRevision') {{ $t('remove') }}
          i.fa.fa-spinner.fa-pulse.ml-1(v-if = '$vueLoading.isLoading(`deposits:terms:revision:${selectedRevision.id}:remove`)')
      .fa-pull-right
        button.btn.btn-success.mx-1(@click = 'earlyTermination') {{ $t('earlyTermination') }}
        button.btn.btn-secondary.mx-1(
          v-if   = 'withdrawable'
          @click = 'withdrawal'
        ) {{ $t('payout') }}
</template>


<script>
import FiTable from '@/components/FiTable'
import { validation, validators } from '@/validation'
import DepositRevisionListItem from '@/views/deposits/DepositRevisionListItem'

export default {
  name: 'deposit-revision-list',

  components: { DepositRevisionListItem, FiTable },

  mixins: [validation],

  model: {
    prop: 'selectedRevision',
    event: 'select'
  },

  props: {
    revisions: {
      type: Array,
      required: true
    },
    selectedRevision: {
      type: Object,
      default: () => ({})
    },
    withdrawable: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    }
  },

  i18nOptions: {},

  data: (vm) => ({
    reasonComment: localStorage.getItem(`revision${vm.selectedRevision.id}_reason`)
  }),

  computed: {
    fields () {
      return [
        {
          key: 'startDate',
          label: this.$t('validFrom')
        },
        {
          key: 'endDate',
          label: this.$t('validUntil')
        },
        {
          key: 'reasonComment',
          label: this.$t('reasonComment')
        },
        {
          key: 'createdBy',
          label: this.$t('createdBy')
        },
        {
          key: 'status',
          label: this.$t('status')
        }
      ]
    },
    selectedRevisionStatus () {
      return this.selectedRevision.status?.code
    },
    reasonCommentModel: {
      get () {
        const { comment } = this.selectedRevision
        return this.reasonComment ?? comment
      },
      set (reason) {
        this.reasonComment = reason
        localStorage.setItem(`revision${this.selectedRevision.id}_reason`, reason)
      }
    }
  },

  watch: {
    reasonCommentModel (newComment) {
      const { comment } = this.selectedRevision
      if (newComment !== comment) {
        this.$v.$touch()
      }
    }
  },

  validations: {
    reasonCommentModel: {
      required: validators.required
    }
  },

  methods: {
    withdrawal () {
      this.$emit('withdrawal')
    },
    earlyTermination () {
      this.$emit('terminate')
    },
    createRevision () {
      this.$emit('create')
    },
    selectRevision (revision) {
      this.$emit('select', revision)
    },
    activateRevision () {
      this.$emit('accept')
    },
    removeRevision () {
      this.$emit('remove')
    },
    saveReason (reason) {
      this.$v.$reset()
      this.$emit('reason', reason)
      localStorage.removeItem(`revision${this.selectedRevision.id}_reason`)
    }
  }
}
</script>


<i18n>
en:
  title:            "Terms history"
  validFrom:        "Valid from"
  validUntil:       "Valid until"
  reasonComment:    "Reason for terms change"
  status:           "Status"
  change:           "Change"
  activate:         "Activate"
  remove:           "Remove"
  earlyTermination: "Early termination"
  payout:           "Payout"
  createdBy:        "Created by"
et:
  title:            "Terms history"
  validFrom:        "Valid from"
  validUntil:       "Valid until"
  reasonComment:    "Reason for terms change"
  status:           "Status"
  change:           "Change"
  activate:         "Activate"
  remove:           "Remove"
  earlyTermination: "Early termination"
  payout:           "Payout"
  createdBy:        "Created by"
ru:
  title:            "Terms history"
  validFrom:        "Valid from"
  validUntil:       "Valid until"
  reasonComment:    "Reason for terms change"
  status:           "Status"
  change:           "Change"
  activate:         "Activate"
  remove:           "Remove"
  earlyTermination: "Early termination"
  payout:           "Payout"
  createdBy:        "Created by"
</i18n>
