<template lang='pug'>
.deposit-statement-list.card
  .card-header {{ $t('title') }}
  .card-body
    fi-table(
      :fields      = 'fields'
      :items       = 'transactions'
      :empty-label = '$t("notFound")'
      loader      = 'deposits:contract:transactions:fetch'
      tbodyClass   = 'scrollBody'
    )
      template(v-slot = '{ items }')
        deposit-statement-list-item(
          v-for        = 'item in items'
          :key         = 'item.id'
          :transaction = 'item'
        )
    fi-pagination(
      v-if    = 'transactionPages > 1'
      :pages  = 'transactionPages'
      v-model = 'pagination.page'
    )
</template>

<script>
import FiTable from '@/components/FiTable'
import DepositStatementListItem from '@/views/deposits/DepositStatementListItem'
import FiPagination from '@/components/FiPagination'
import { pagination } from '@/const'

export default {
  name: 'deposit-statement-list',

  components: { DepositStatementListItem, FiTable, FiPagination },

  props: {
    transactions: {
      type: Array,
      required: true
    },
    transactionPages: {
      type: Number,
      required: true
    }
  },

  i18nOptions: {},

  data: () => ({
    pagination: { ...pagination }
  }),

  computed: {
    fields () {
      return [
        {
          key: 'date',
          label: this.$t('date')
        },
        {
          key: 'info',
          label: this.$t('info')
        },
        {
          key: 'amount',
          label: this.$t('amount'),
          class: 'money'
        }
      ]
    }
  },

  watch: {
    pagination: {
      handler (pagination) {
        this.$emit('change-page', pagination)
      },
      deep: true
    }
  }
}
</script>


<i18n>
en:
  title:    "Deposit statement"
  date:     "Date"
  info:     "Info"
  amount:   "Amount"
  notFound: "No deposit statements"
et:
  title:    "Deposit statement"
  date:     "Date"
  info:     "Info"
  amount:   "Amount"
  notFound: "No deposit statements"
ru:
  title:    "Deposit statement"
  date:     "Date"
  info:     "Info"
  amount:   "Amount"
  notFound: "No deposit statements"
</i18n>


<style scoped lang="scss">
@import '~bootstrap/scss/mixins';
@import 'scss/variables';
@include media-breakpoint-up(lg) {
  .deposit-statement-list {
    & /deep/ thead, & /deep/ tbody tr, & /deep/ tfoot {
      display:table;
      width:100%;
      table-layout:fixed;/* even columns width , fix width of table too*/
    }
    & /deep/ .scrollBody {
      max-height: 700px;
      overflow: auto;
      display: block;
    }
  }
}
</style>
