<template lang='pug'>
.deposit-financials.row.animated.fadeIn
  .col-9
    .card
      .card-header {{ $t('companyLiabilities', { companyName: settingByKey('creditIssuerName') }) }}
      .card-body
        table.table.table-hover
          thead: tr
            th.money
              small.text-muted {{ $t('depositBalance') }}
              button.btn.btn-link.btn-block.px-0.text-right(
                @click = 'openTransactionsModal({ accountType: "DEPOSIT_BALANCE", amount: depositFinancials.depositBalance, correction: true })'
              )
                .h4 {{ depositFinancials.depositBalance || 0 | abs | money }}
            th.money
              small.text-muted {{ $t('depositPayable') }}
              button.btn.btn-link.btn-block.px-0.text-right(
                :class = '{ "text-danger": totalDepositBalance }'
                @click = 'openTransactionsModal({ accountType: ["DEPOSIT_PAYABLE","DEPOSIT_PAID"], amount: totalDepositBalance })'
              )
                .h4 {{ totalDepositBalance | abs | money }}
            th.money
              small.text-muted {{ $t('interestPayable') }}
              button.btn.btn-link.btn-block.px-0.text-right(
                @click = 'openTransactionsModal({ accountType: ["DEPOSIT_INTEREST_PAYABLE", "DEPOSIT_INTEREST_PAID"], amount: totalInterestBalance })'
              )
                .h4 {{ totalInterestBalance || 0 | abs | money }}
            th.money
              small.text-muted {{ $t('withholdTax') }}
              button.btn.btn-link.btn-block.px-0.text-right(
                @click = 'openTransactionsModal({ accountType: ["DEPOSIT_WH_TAX_PAYABLE", "DEPOSIT_WH_TAX_PAID"], amount: totalWithholdBalance })'
              )
                .h4 {{ totalWithholdBalance || 0 | abs | money }}
            th.money
              small.text-muted {{ $t('totalPayable') }}
              .btn.btn-link.btn-block.px-0.text-right
                .h4.text-primary {{ totalPayableBalance || 0 | abs | money }}
            th.money
              small.text-muted {{ $t('interestAccrued') }}
              button.btn.btn-link.btn-block.px-0.text-right(
                @click = 'openTransactionsModal({ accountType: "DEPOSIT_INTEREST_ACCRUED", correction: true })'
              )
                .h4 {{ depositFinancials.depositInterestAccruedBalance || 0 | abs | money }}
            th.money
              small.text-muted {{ $t('totalLiabilities') }}
              .btn.btn-link.btn-block.px-0.text-right
                .h4.text-primary {{ totalLiabilities || 0 | abs | money }}
          tbody: tr
            td.money {{ $t('payables') }}
            td
              button.btn.btn-link.btn-block.p-0.text-right(
                @click = 'openTransactionsModal({ accountType: "DEPOSIT_PAYABLE", correction: true })'
              ) {{ depositFinancials.depositPayableBalance || 0 | abs | money }}
            td
              button.btn.btn-link.btn-block.p-0.text-right(
                @click = 'openTransactionsModal({ accountType: "DEPOSIT_INTEREST_PAYABLE", correction: true })'
              ) {{ depositFinancials.depositInterestPayableBalance || 0 | abs | money }}
            td
              button.btn.btn-link.btn-block.p-0.text-right(
                @click = 'openTransactionsModal({ accountType: "DEPOSIT_WH_TAX_PAYABLE", correction: true })'
              ) {{ depositFinancials.withholdingTaxPayableBalance || 0 | abs | money }}
            td
              .btn.btn-link.btn-block.p-0.text-right {{ totalPayable || 0 | abs | money }}
            td
            td
          tbody: tr
            td.money {{ $t('payments') }}
            td
              button.btn.btn-link.btn-block.p-0.text-right(
                @click = 'openTransactionsModal({ accountType: "DEPOSIT_PAID", correction: true })'
              ) {{ depositFinancials.depositPaidBalance || 0 | abs | money }}
            td
              button.btn.btn-link.btn-block.p-0.text-right(
                @click = 'openTransactionsModal({ accountType: "DEPOSIT_INTEREST_PAID", correction: true })'
              ) {{ depositFinancials.depositInterestPaidBalance || 0 | abs | money }}
            td
              button.btn.btn-link.btn-block.p-0.text-right(
                @click = 'openTransactionsModal({ accountType: "DEPOSIT_WH_TAX_PAID", correction: true })'
              ) {{ depositFinancials.withholdingTaxPaidBalance || 0 | abs | money }}
            td
              .btn.btn-link.btn-block.p-0.text-right {{ totalCost || 0 | abs | money }}
            td
            td
  .col-3
    .card
      .card-header {{ $t('customerLiabilities', { customerName: `${depositContract.customer.firstName} ${depositContract.customer.lastName}` }) }}
      .card-body
        table.table.table-hover
          thead: tr
            th
            th.money
              small.text-muted {{ $t('feeDebt') }}
              button.btn.btn-link.btn-block.px-0.text-right(@click = 'openTransactionsModal({ accountType: ["LEDGER_FEE_RECEIVABLE", "LEDGER_FEE_INCOME"], amount: feeBalance, provision: feeBalance > 0 })')
                .h4 {{ feeBalance || 0 | abs | money }}
          tbody: tr
            td.money {{ $t('receivables') }}
            td
              button.btn.btn-link.btn-block.p-0.text-right(@click = 'openTransactionsModal({ accountType: "LEDGER_FEE_RECEIVABLE", correction: true })') {{ depositFinancials.feeReceivableBalance || 0 | abs | money }}
          tbody: tr
            td.money {{ $t('clientPayments') }}
            td
              button.btn.btn-link.btn-block.p-0.text-right(@click = 'openTransactionsModal({ accountType: "LEDGER_FEE_INCOME", correction: true })') {{ depositFinancials.feeIncomeBalance || 0 | abs | money }}
  deposit-financials-list-modal(
    v-model             = 'showTransactionsModal'
    :account-type       = 'accountType'
    :total-debt         = 'accountTotalDebt'
    :transaction-state  = 'depositTransactions'
    :transactions-pages = 'depositTransactionPages'
    loader              = 'deposits:contract:transactions:fetch'
    :correction         = 'correctionEnabled'
    :provision          = 'provisionEnabled'
    @search             = 'loadDepositTransactions'
    @update             = 'loadDepositContractFinancials'
  )
</template>

<script>
import DepositFinancialsListModal from '@/views/deposits/DepositFinancialsListModal'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'deposit-financials',

  components: { DepositFinancialsListModal },

  filters: {
    abs (value) {
      return Math.abs(value)
    }
  },

  i18nOptions: {},

  data: () => ({
    correctionEnabled: false,
    provisionEnabled: false,
    showTransactionsModal: false,
    accountType: '',
    accountTotalDebt: '',
    searchParams: {
      sort: 'id,desc'
    }
  }),

  computed: {
    ...mapState('deposits', ['depositContract', 'depositFinancials', 'depositTransactions', 'depositTransactionPages']),
    ...mapGetters('settings', ['settingByKey']),
    totalPayable () {
      const {
        depositPayableBalance = 0,
        depositInterestPayableBalance = 0,
        withholdingTaxPayableBalance = 0
      } = this.depositFinancials
      return depositPayableBalance + depositInterestPayableBalance + withholdingTaxPayableBalance
    },
    totalCost () {
      const {
        depositPaidBalance = 0,
        depositInterestPaidBalance = 0,
        withholdingTaxPaidBalance = 0
      } = this.depositFinancials
      return depositPaidBalance + depositInterestPaidBalance + withholdingTaxPaidBalance
    },
    feeBalance () {
      const {
        feeReceivableBalance = 0,
        feeIncomeBalance = 0
      } = this.depositFinancials
      return feeReceivableBalance - feeIncomeBalance
    },
    totalDepositBalance () {
      const {
        depositPayableBalance = 0,
        depositPaidBalance = 0
      } = this.depositFinancials
      return (depositPayableBalance - depositPaidBalance) || 0
    },
    totalInterestBalance () {
      const {
        depositInterestPayableBalance = 0,
        depositInterestPaidBalance = 0
      } = this.depositFinancials
      return depositInterestPayableBalance - depositInterestPaidBalance
    },
    totalWithholdBalance () {
      const {
        withholdingTaxPayableBalance = 0,
        withholdingTaxPaidBalance = 0
      } = this.depositFinancials
      return withholdingTaxPayableBalance - withholdingTaxPaidBalance
    },
    totalPayableBalance () {
      return this.totalDepositBalance + this.totalInterestBalance + this.totalWithholdBalance
    },
    totalLiabilities () {
      const {
        depositInterestAccruedBalance = 0,
        depositBalance = 0
      } = this.depositFinancials
      return this.totalPayableBalance + depositInterestAccruedBalance + depositBalance
    }
  },

  created () {
    this.loadDepositContractFinancials({ contractId: this.id })
  },

  methods: {
    ...mapActions('deposits', ['loadDepositContractTransactions', 'loadDepositContractFinancials']),
    openTransactionsModal ({ accountType, amount, correction = false, provision = false }) {
      this.accountTotalDebt = amount
      this.accountType = accountType
      this.showTransactionsModal = true
      this.correctionEnabled = correction
      this.provisionEnabled = provision
    },
    loadDepositTransactions ({ filters }) {
      this.loadDepositContractTransactions({ filters: { accountTypes: this.accountType, ...filters, ...this.searchParams } })
    }
  }
}
</script>


<i18n>
en:
  companyLiabilities:  "{{ companyName }}"
  customerLiabilities: "{{ customerName }}"
  depositBalance:      "Deposit balance"
  depositPayable:      "Deposit payable"
  interestPayable:     "Interest payable"
  withholdTax:         "Withhold tax"
  totalPayable:        "Total payable"
  interestAccrued:     "Interest accrued"
  totalLiabilities:    "Total liabilities"
  payables:            "Payables"
  payments:            "Payments"
  feeDebt:             "Fee debt"
  receivables:         "Receivables"
  clientPayments:      "Client payments"
et:
  companyLiabilities:  "{{ companyName }}"
  customerLiabilities: "{{ customerName }}"
  depositBalance:      "Deposit balance"
  depositPayable:      "Deposit payable"
  interestPayable:     "Interest payable"
  withholdTax:         "Withhold tax"
  totalPayable:        "Total payable"
  interestAccrued:     "Interest accrued"
  totalLiabilities:    "Total liabilities"
  payables:            "Payables"
  payments:            "Payments"
  feeDebt:             "Fee debt"
  receivables:         "Receivables"
  clientPayments:      "Client payments"
ru:
  companyLiabilities:  "{{ companyName }}"
  customerLiabilities: "{{ customerName }}"
  depositBalance:      "Deposit balance"
  depositPayable:      "Deposit payable"
  interestPayable:     "Interest payable"
  withholdTax:         "Withhold tax"
  totalPayable:        "Total payable"
  interestAccrued:     "Interest accrued"
  totalLiabilities:    "Total liabilities"
  payables:            "Payables"
  payments:            "Payments"
  feeDebt:             "Fee debt"
  receivables:         "Receivables"
  clientPayments:      "Client payments"
</i18n>
