<template lang='pug'>
.deposit-terms-view.mb-4
  v-loading(:loader = '["deposits:terms:history:fetch", "deposits:product:fetch"]')
    template(#spinner)
      .text-center: i.fa.fa-spinner.fa-pulse.fa-lg.m-4
    deposit-revision-list(
      v-if          = 'showRevisions'
      v-model       = 'selectedRevisionModel'
      :revisions    = 'revisions'
      :withdrawable = 'isWithdrawable'
      :editable     = 'isActive'
      @create       = 'createRevision'
      @remove       = 'deleteRevision'
      @accept       = 'acceptRevision'
      @withdrawal   = 'showWithdrawalModal = true'
      @reason       = 'saveReason'
      @terminate    = 'showTerminateModal = true'
    )
    .row.row-cols-1.row-cols-md-2
      .col
        deposit-details.h-100(
          :deposit     = 'depositContract'
          :product     = 'depositProduct'
          :fee-configs  = 'feeConfigs'
        )
      .col
        deposit-terms(
          :deposit     = 'depositContract'
          :product     = 'depositProduct'
          :revision    = 'selectedRevision'
          :is-editable = 'isEditable'
        )
        contract-custom-fields-data(
          :custom-fields = 'customFieldsValues'
          :options       = 'depositCustomFields'
          loader         = 'deposit:custom-fields:save'
          @submit        = 'saveCustomFields'
        )
        deposit-payout.mb-0(
          :related-payment = 'depositPayout'
          :is-editable     = 'isEditable'
        )
    deposit-withdrawal-modal(
      v-model            = 'showWithdrawalModal'
      :deposit           = 'depositContract'
      :loader            = '$vueLoading.isLoading("deposits:contract:withdrawal:save")'
      :ok-action         = 'doWithdrawal'
      :product           = 'depositProduct'
      :payment-relations = 'depositPaymentRelations'
      :fee-configs        = 'feeConfigs'
    )
    deposit-terminate-modal(
      v-model            = 'showTerminateModal'
      :deposit           = 'depositContract'
      :product           = 'depositProduct'
      :financial         = 'depositFinancials'
      :loader            = '$vueLoading.isLoading("deposits:contract:terminate:save")'
      :ok-action         = 'doTerminate'
      :payment-relations = 'depositPaymentRelations'
      :fee-configs       = 'feeConfigs'
    )
</template>


<script>
import { loanStatuses } from '@/const'
import DepositDetails from '@/views/deposits/DepositDetails'
import DepositRevisionList from '@/views/deposits/DepositRevisionList'
import DepositTerminateModal from '@/views/deposits/DepositTerminateModal'
import DepositTerms from '@/views/deposits/DepositTerms'
import DepositWithdrawalModal from '@/views/deposits/DepositWithdrawalModal'
import { mapActions, mapState } from 'vuex'
import orderBy from 'lodash/orderBy'
import DepositPayout from '@/views/deposits/DepositPayout'
import ContractCustomFieldsData from '@/views/loans/LoanTerms/ContractCustomFieldsData'

const paymentRelation = {
  customerId: '',
  paymentWayId: '',
  beneficiaryName: '',
  beneficiaryExternalAccountNumber: '',
  beneficiaryIdCode: '',
  amount: ''
}

export default {
  name: 'deposit-terms-view',

  components: {
    ContractCustomFieldsData,
    DepositPayout,
    DepositTerminateModal,
    DepositWithdrawalModal,
    DepositRevisionList,
    DepositTerms,
    DepositDetails
  },

  data: () => ({
    selectedRevision: {},
    showWithdrawalModal: false,
    showTerminateModal: false
  }),

  computed: {
    ...mapState('deposits', ['depositContract', 'depositProduct', 'depositTermsHistory', 'depositFinancials', 'depositPaymentRelations', 'customFieldsValues']),
    ...mapState('products', ['feeConfigs']),
    ...mapState('customFields', ['customFields']),
    depositPayout () {
      return this.depositPaymentRelations?.[0] ?? {
        ...paymentRelation,
        amount: this.depositContract.currentBalance
      }
    },
    showRevisions () {
      return [loanStatuses.active, loanStatuses.matured, loanStatuses.terminated].includes(this.depositContract.status.id)
    },
    isActive () {
      return this.depositContract.status.id === loanStatuses.active
    },
    isWithdrawable () {
      return this.isActive && /FLEXIBLE_DEPOSIT/ig.test(this.depositProduct.productType)
    },
    revisions () {
      return orderBy(this.depositTermsHistory, ['validUntil', 'id'], ['desc', 'desc'])
    },
    activeRevision () {
      return this.revisions.find(({ status }) => status.code === loanStatuses.active)
    },
    isEditable () {
      switch (this.depositContract.status.code) {
        case 'ACTIVE':
          return /PENDING/i.test(this.selectedRevision?.status?.code)
        case 'MATURED':
        case 'CLOSED':
        case 'TERMINATED':
          return false
        default:
          return true
      }
    },
    selectedRevisionModel: {
      get () {
        return this.selectedRevision
      },
      set (revision) {
        this.selectRevision(revision)
      }
    },
    depositCustomFields () {
      return this.depositProduct?.customFields?.map(field => ({ ...field, customField: this.customFields.find(({ id }) => id === field.customFieldId) }))
        .filter(({ customField }) => customField?.enabled)
        .sort((a, b) => a.fieldOrder - b.fieldOrder)
    }
  },

  async created () {
    await this.loadFeeConfig({ group: 'DEPOSIT' })
    await this.loadCustomFields()
    await this.loadDepositCustomFields({ contractId: this.depositContract.id, productGroup: 'DEPOSIT' })
    this.loadDepositTermsRevisionsClassifiers({ contractId: this.depositContract.id })
    if (this.showRevisions) {
      await this.loadDepositHistory({ contractId: this.depositContract.id })
      this.selectRevision(this.activeRevision)
    }
    await this.loadDepositPaymentRelations()
    await this.loadDepositContractFinancials({ contractId: this.depositContract.id })
  },

  methods: {
    ...mapActions('products', ['loadFeeConfig']),
    ...mapActions('deposits', [
      'loadDepositHistory',
      'createDepositTermsRevision',
      'removeDepositTermsRevision',
      'saveDepositTermsRevisionAccept',
      'saveDepositTermsRevisionReasonComment',
      'loadDepositContractByRevision',
      'saveDepositContractWithdrawal',
      'saveDepositContractTerminate',
      'loadDepositPaymentRelations',
      'loadDepositContractFinancials',
      'loadDepositCustomFields',
      'saveDepositCustomFields'
    ]),
    ...mapActions('classifiers', ['loadDepositTermsRevisionsClassifiers']),
    ...mapActions('customFields', ['loadCustomFields']),
    async createRevision () {
      const { autoExtendable, amountRangePeriodInterests, payoutBankAccount } = this.depositContract.terms
      this.selectedRevisionModel = await this.createDepositTermsRevision({
        contractId: this.depositContract.id,
        revisionData: {
          autoExtendable,
          interests: amountRangePeriodInterests.reduce((res, { periodRangeInterests }, idx) => ({ ...res, [idx]: periodRangeInterests.map(({ interest }) => interest) }), {}),
          payoutBankAccount
        }
      })
    },
    async deleteRevision () {
      await this.removeDepositTermsRevision({ contractId: this.depositContract.id, revisionId: this.selectedRevision.id })
      this.selectedRevisionModel = this.activeRevision
    },
    async acceptRevision () {
      await this.saveDepositTermsRevisionAccept({ contractId: this.depositContract.id })
      await this.loadDepositHistory({ contractId: this.depositContract.id })
      this.selectedRevisionModel = this.activeRevision
    },
    saveReason (reason) {
      this.saveDepositTermsRevisionReasonComment({ contractId: this.depositContract.id, revisionId: this.selectedRevision.id, commentData: reason })
    },
    selectRevision (revision) {
      this.selectedRevision = revision
      this.loadDepositContractByRevision({ contractId: this.depositContract.id, revisionId: revision.id })
    },
    async doWithdrawal (withdrawalData) {
      await this.saveDepositContractWithdrawal({ contractId: this.depositContract.id, withdrawalData })
    },
    async doTerminate (terminateData) {
      await this.saveDepositContractTerminate({ contractId: this.depositContract.id, terminateData })
    },
    async saveCustomFields ({ data, done }) {
      await this.saveDepositCustomFields({ contractId: this.depositContract.id, customFieldData: data, productGroup: 'DEPOSIT' })
      done()
    }
  }
}
</script>
