<template lang='pug'>
tr.deposit-statement-list-item
  td {{ $t('common:formatDate', { value: transaction.date }) }}
  td {{ transaction.description }}
  td.money
    div(:class='debitCreditClasses') {{ transaction.amount | money }}
</template>


<script>
export default {
  name: 'deposit-statement-list-item',

  props: {
    transaction: {
      type: Object,
      required: true
    }
  },

  computed: {
    debitCreditClasses () {
      return {
        'text-success': this.transaction.credit,
        'text-error': !this.transaction.credit
      }
    }
  }
}
</script>
