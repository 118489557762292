<template lang="pug">
.deposit-communication
  v-loading(loader = 'customer:fetch')
    .text-center(slot = 'spinner'): i.fa.fa-spinner.fa-pulse.fa-lg
    customer-communication-view(
      store-module           = 'deposits'
      resources-state        = 'depositResources'
      resources-pages-state  = 'depositResourcesPages'
      comments-state         = 'depositComments'
      :resource-action       = 'resourceAction'
      commentable-type       = 'DEPOSIT_CONTRACT'
      messages-state         = 'depositMessages'
      messages-pages-state   = 'depositMessagesPages'
      customer-history-state = 'depositCustomerHistory'
      customer-state         = 'depositCustomer'
    )
</template>


<script>
import CustomerCommunicationView from '@/views/customers/CustomerCommunicationView'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'deposit-communication',

  components: { CustomerCommunicationView },

  computed: {
    ...mapState('deposits', ['depositContract'])
  },

  created () {
    this.loadCustomerHistory({ customerId: this.depositContract.customer.id })
  },

  methods: {
    ...mapActions('deposits', ['createDepositResource', 'loadCustomerHistory']),
    resourceAction ({ resourceData }) {
      return this.createDepositResource({
        resourceData: {
          ...resourceData,
          documentRelation: 'DEPOSIT_CONTRACT'
        },
        customerId: this.depositContract.customer.id,
        contractId: this.depositContract.id
      })
    }
  }
}
</script>
