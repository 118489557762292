<template lang='pug'>
.deposit-overview-details.card
  .card-header {{ $t('title') }}
  .card-body
    fi-data-field(:label = '$t("reference")')
      router-link(
        :to  = '{ name: "CustomerPaymentInfo", params: { id: deposit.customer.id, reference: deposit.paymentAccountReference } }'
      ) {{ deposit.paymentAccountReference }}
    fi-data-field(:label = '$t("amountInitial")') {{ deposit.terms.amount | money }}
    fi-data-field(:label = '$t("amountCurrent")') {{ deposit.currentBalance || 0 | money }}
    fi-data-field(:label = '$t("period")') {{ period }}
    fi-data-field(:label = '$t("startDate")') {{ $t('common:formatDate', { value: deposit.terms.startDate }) }}
    fi-data-field(:label = '$t("endDate")') {{ $t('common:formatDate', { value: deposit.terms.endDate }) }}
    fi-data-field(:label = '$t("interestRate")') {{ deposit.terms.finalInterestRate | percent }}
    fi-data-field(:label = '$t("interestFreq")') {{ interestFrequency.human }}
    fi-data-field(:label = '$t("withholdIncomeTax")')
      .row
        .col
          fi-switch(:value = 'deposit.terms.withholdTax.enabled'
                    read-only)
        .col {{ deposit.terms.withholdTax.rate | percent}}
    fi-data-field(:label = '$t("taxResidency")') {{ classifierById('countries', deposit.terms.withholdTax.residenceCountryId).human }}
    fi-data-field(:label = '$t("autoExtend")')
      fi-switch(:value = 'deposit.terms.autoExtendable.extendable'
                read-only)
    fi-data-field(:label = '$t("moneyOrigin")') {{ deposit.terms.originOfMoney }}
    fi-data-field(:label = '$t("beneficiary")') {{ deposit.terms.beneficiary }}
    fi-data-field(v-if = '!deposit.terms.compoundInterest' :label = '$t("nextPayment")')
      .row
        .col {{ deposit.terms.nextPayoutAmount |money }}
        .col {{ $t('common:formatDate', { value: deposit.terms.nextPayoutDate }) }}
</template>


<script>
import FiDataField from '@/components/FiDataField'
import FiSwitch from '@/components/FiSwitch'
import { validation } from '@/validation'
import { mapGetters } from 'vuex'

export default {
  name: 'deposit-overview-details',

  components: { FiSwitch, FiDataField },

  mixins: [validation],
  props: {
    deposit: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  computed: {
    ...mapGetters('classifiers', ['classifierById', 'classifierByName']),
    period () {
      const { quantity, unitType } = this.deposit.terms.period
      return this.$t(this.classifierByName(unitType.classifier, unitType.code)?.human, { count: quantity })
    },
    interestFrequency () {
      const { code, classifier } = this.deposit.terms.interestPaymentMethod.type
      return this.classifierByName(classifier, code) ?? {}
    }
  }
}
</script>


<i18n>
en:
  title:                  "Deposit details"
  amountInitial:          "Initial amount"
  amountCurrent:          "Current amount"
  period:                 "Deposit period"
  Day:                    "{{ count }} day"
  Day_plural:             "{{ count }} days"
  Week:                   "{{ count }} week"
  Week_plural:            "{{ count }} weeks"
  Month:                  "{{ count }} month"
  Month_plural:           "{{ count }} months"
  Year:                   "{{ count }} year"
  Year_plural:            "{{ count }} years"
  startDate:              "Start date"
  endDate:                "End date"
  interestFreq:           "Interest payment frequency"
  interestRate:           "Interest rate"
  reference:              "Reference"
  payoutBankAccount:      "Payout bank account"
  moneyOrigin:            "Origin of money"
  beneficiary:            "Beneficiary"
  withholdIncomeTax:      "Withhold income tax and rate"
  taxResidency:           "Residency for tax purposes"
  countryPlaceholder:     "Select country"
  autoExtend:             "Extend automatically"
  nextPayment:            "Next payment"
et:
  title:                  "Deposit details"
  amountInitial:          "Initial amount"
  amountCurrent:          "Current amount"
  period:                 "Deposit period"
  Day:                    "{{ count }} day"
  Day_plural:             "{{ count }} days"
  Week:                   "{{ count }} week"
  Week_plural:            "{{ count }} weeks"
  Month:                  "{{ count }} month"
  Month_plural:           "{{ count }} months"
  Year:                   "{{ count }} year"
  Year_plural:            "{{ count }} years"
  startDate:              "Start date"
  endDate:                "End date"
  interestFreq:           "Interest payment frequency"
  interestRate:           "Interest rate"
  reference:              "Reference"
  payoutBankAccount:      "Payout bank account"
  moneyOrigin:            "Origin of money"
  beneficiary:            "Beneficiary"
  withholdIncomeTax:      "Withhold income tax and rate"
  taxResidency:           "Residency for tax purposes"
  countryPlaceholder:     "Select country"
  autoExtend:             "Extend automatically"
  nextPayment:            "Next payment"
ru:
  title:                  "Deposit details"
  amountInitial:          "Initial amount"
  amountCurrent:          "Current amount"
  period:                 "Deposit period"
  Day_0:                  "{{ count }} день"
  Day_1:                  "{{ count }} дня"
  Day_2:                  "{{ count }} дней"
  Week_0:                 "{{ count }} неделя"
  Week_1:                 "{{ count }} недели"
  Week_2:                 "{{ count }} недель"
  Month_0:                "{{ count }} месяц"
  Month_1:                "{{ count }} месяца"
  Month_2:                "{{ count }} месяцев"
  Year_0:                 "{{ count }} год"
  Year_1:                 "{{ count }} года"
  Year_2:                 "{{ count }} лет"
  startDate:              "Start date"
  endDate:                "End date"
  interestFreq:           "Interest payment frequency"
  interestRate:           "Interest rate"
  reference:              "Reference"
  payoutBankAccount:      "Payout bank account"
  moneyOrigin:            "Origin of money"
  beneficiary:            "Beneficiary"
  withholdIncomeTax:      "Withhold income tax and rate"
  taxResidency:           "Residency for tax purposes"
  countryPlaceholder:     "Select country"
  autoExtend:             "Extend automatically"
  nextPayment:            "Next payment"
</i18n>
