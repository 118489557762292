<template lang="pug">
tr.deposit-list-item
  td: router-link(:to = 'deposit.id.toString()'
                  append) {{ deposit.contractNumber }}
  td: router-link(:to = '{ name: "Customer", params: { id: deposit.customer.id }}') {{ deposit.customer.fullName }} ({{ deposit.customer.idCode }})
  td {{ product.name }}
  td.money {{ deposit.initialAmount | money }}
  td.money {{ deposit.balance | money }}
  td.money {{ deposit.accruedInterest | money }}
  td {{ deposit.paymentAccountReference }}
  td.text-nowrap {{ $t('common:formatDate', { value: deposit.startDate }) }}
  td.text-nowrap {{ $t('common:formatDate', { value: deposit.endDate }) }}
  td.text-nowrap {{ $t('common:formatDate', { value: !deposit.compoundInterest ? deposit.nextPaymentDate : '' }) }}
  td.money {{ deposit.nextPaymentAmount | money }}
  td.text-nowrap
    fi-switch(
      :value = 'deposit.autoExtendable'
      read-only
    )
  td.text-nowrap
    router-link(v-if = 'deposit.manager'
                :to  = '{ name: "Customer", params: { id: deposit.manager.id }}') {{ deposit.manager.fullName }}
    template(v-else-if = '$vueLoading.isLoading(`deposits:contract:${deposit.id}:manager:save`)')
      | {{ $t('assigning') }}
      |
      i.fa.fa-spinner.fa-pulse
    button.btn.btn-link.p-0(v-else
                        @click.prevent = 'onAssign') {{ $t('assignToMe') }}
  td: .badge(:class = '`badge-${loanStatusColor(status.id)}`') {{ status.human }}
</template>


<script>
import FiSwitch from '@/components/FiSwitch'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'deposit-list-item',
  components: { FiSwitch },
  props: {
    deposit: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters('classifiers', ['classifierByName']),
    ...mapGetters('products', ['productById']),
    ...mapGetters('loans', ['loanStatusColor']),
    ...mapGetters('customers', ['customerName']),
    ...mapState('auth', ['user']),
    product () {
      return this.productById(this.deposit.productId)
    },
    status () {
      const { classifier, code } = this.deposit.status
      return this.classifierByName(classifier, code) ?? {}
    },
    name () {
      return this.customerName(this.deposit.customer)
    },
    manager () {
      return this.deposit.manager ? `${this.customerName(this.deposit.manager)} (${this.deposit.manager.idCode})` : ''
    }
  },
  methods: {
    onAssign () {
      this.$emit('set-manager', { contractId: this.deposit.id, managerId: this.user.id })
    }
  }
}
</script>


<i18n>
en:
  assignToMe: "Assign to me"
  assigning:  "Assigning..."
et:
  assignToMe: "Assign to me"
  assigning:  "Assigning..."
ru:
  assignToMe: "Assign to me"
  assigning:  "Assigning..."
</i18n>
