<template lang='pug'>
.deposit-overview
  customer-note-list(:comments="depositComments")
  .row
    .col-sm-6.col-lg-4
      loan-overview-widget(
        :title = '$t("depositStatus")'
        :value = 'status.human'
        :color = 'loanStatusColor(status.name)'
      )
        loan-status-dropdown.fa-pull-right(
          :statuses  = 'availableStatuses'
          :header    = '$t("statusSelect")'
          @submit    = 'changeStatus'
        )
        fi-confirm-modal(
          v-model      = 'showConfirmModal'
          :ok-callback = 'setStatus'
          :loader      = '`deposits:contract:${depositContract.id}:status:save`'
        )
    .col-sm-6.col-lg-4: loan-overview-widget(
      :title = '$t("prepayment")'
      :value = 'depositContract.prepaymentBalance | money'
      :color = 'depositContract.prepaymentBalance ? "success" : "primary"'
    )
    .col-sm-6.col-lg-4(v-if = 'isActiveContract')
      loan-overview-widget(
        :title = '$t("interestAccrued")'
        :value = 'depositContract.interestAccruedBalance | money'
      )
        .fa-pull-right.text-right
          h4.m-0 {{ (depositFinancials.depositInterestPayableBalance || 0 + depositFinancials.withholdingTaxPayableBalance || 0) | money }}
          p.m-0 {{ $t('interestPaid') }}
  .row
    .col-lg-4
      deposit-overview-details(
        :deposit   = 'depositContract'
        :product   = 'depositProduct'
      )
      deposit-creation-data(:deposit = 'depositContract')
      loan-overview-customer(
        :customer     = 'depositManager'
        :customerType = '$t("manager")'
      )
        button.btn.text-danger(
          v-if   = 'depositManager'
          @click = 'setManager()'
        )
          i.fa.fa-cog.fa-pulse(v-if = '$vueLoading.isLoading(managerLoader)')
          i.cil-user-unfollow(v-else)
        application-assign-dropdown.colored(
          :application  = 'depositContract'
          :loader       = 'managerLoader'
          no-caret
          @assign       = 'setManager'
        )

    .col-lg
      deposit-statement-list(
        :transactions = 'depositTransactions'
        :transaction-pages = 'depositTransactionPages'
        @change-page = 'searchTransactions'
      )
</template>


<script>
import FiConfirmModal from '@/components/FiConfirmModal'
import CustomerNoteList from '@/views/customers/CustomerNoteList'
import DepositCreationData from '@/views/deposits/DepositCreationData'
import DepositOverviewDetails from '@/views/deposits/DepositOverviewDetails'
import DepositStatementList from '@/views/deposits/DepositStatementList'
import LoanOverviewWidget from '@/views/loans/LoanOverview/LoanOverviewWidget'
import LoanStatusDropdown from '@/views/loans/LoanStatusDropdown'
import { mapActions, mapGetters, mapState } from 'vuex'
import ApplicationAssignDropdown from '@/views/applications/ApplicationAssignDropdown'
import LoanOverviewCustomer from '@/views/loans/LoanOverview/LoanOverviewCustomer'
import api from '@/api'
import { loanStatuses } from '@/const'

export default {
  name: 'deposit-overview',

  components: {
    LoanOverviewCustomer,
    ApplicationAssignDropdown,
    FiConfirmModal,
    DepositOverviewDetails,
    CustomerNoteList,
    DepositStatementList,
    DepositCreationData,
    LoanOverviewWidget,
    LoanStatusDropdown
  },

  i18nOptions: {},

  data: () => ({
    newStatus: null,
    showConfirmModal: false,
    depositManager: null
  }),

  computed: {
    ...mapState('deposits', [
      'depositContract',
      'depositProduct',
      'depositAudit',
      'depositTransactions',
      'depositTransactionPages',
      'depositComments',
      'depositFinancials'
    ]),
    ...mapGetters('loans', ['loanStatusColor']),
    ...mapGetters('classifiers', ['classifierByName', 'optionsFromClassifier']),
    status () {
      const { classifier, code } = this.depositContract?.status || {}
      return this.classifierByName(classifier, code) ?? {}
    },
    availableStatuses () {
      const { classifier } = this.depositContract?.status || {}
      return this.optionsFromClassifier(classifier)
        .filter(({ value }) => this.depositContract.statusesForManualChange.includes(value))
        .map(({ value }) => ({ classifier, id: value }))
    },
    isActiveContract () {
      const { active, matured, terminated } = loanStatuses
      return [active, matured, terminated].includes(this.depositContract?.status?.code)
    },
    managerLoader () {
      return `deposits:contract:${this.depositContract.id}:manager:save`
    }
  },

  watch: {
    'depositContract.managerId': {
      async handler (managerId) {
        if (!managerId) return
        this.depositManager = (await api.getCustomer({ id: managerId })).data
      },
      immediate: true
    }
  },

  async created () {
    this.searchTransactions()
    this.loadComments({ filters: { active: true, hasAction: false } })
  },

  methods: {
    ...mapActions('deposits', ['setDepositContractStatus', 'loadDepositContractTransactions', 'loadComments', 'setDepositContractManager']),
    changeStatus (status) {
      this.showConfirmModal = true
      this.newStatus = status
    },
    async setStatus ({ done }) {
      await this.setDepositContractStatus({ contractId: this.depositContract.id, statusId: this.newStatus.id })
      done()
    },
    setManager ({ id = this.depositContract.id, customerId = '' } = {}) {
      this.setDepositContractManager({ contractId: this.depositContract.id, managerId: customerId })
    },
    searchTransactions (pagination = {}) {
      this.loadDepositContractTransactions({ filters: { accountTypes: 'PREPAYMENT', ...pagination } })
    }
  }
}
</script>


<i18n>
en:
  depositStatus:   "Deposit status"
  statusSelect:    "Select new status"
  prepayment:      "Prepayment"
  interestAccrued: "Interest accrued"
  interestPaid:    "Interest paid"
  manager:         "Manager"
et:
  depositStatus:   "Deposit status"
  statusSelect:    "Select new status"
  prepayment:      "Prepayment"
  interestAccrued: "Interest accrued"
  interestPaid:    "Interest paid"
  manager:         "Manager"
ru:
  depositStatus:   "Deposit status"
  statusSelect:    "Select new status"
  prepayment:      "Prepayment"
  interestAccrued: "Interest accrued"
  interestPaid:    "Interest paid"
  manager:         "Manager"
</i18n>
